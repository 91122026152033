/* eslint-disable @typescript-eslint/no-empty-function */
import React, { lazy, Suspense, useEffect } from 'react'
import { Navigate, useOutletContext } from 'react-router-dom'
import Loader from 'components/Loader'
import { APP_ROUTES } from 'const/router'
import { ICustomRouteObject, IOutletAuth } from 'types/auth'
import { componentDetailsObj } from 'const/componentName'
import Services from 'modules/Dashboard copy'
import { onTrim } from 'utils/common'

const CopyAccountLayout = lazy(() => import('../layouts/AccountLayout/Copy-AccountLayout copy'))
const CommonLayout = lazy(() => import('../layouts/CommonLayout'))
const MainLayout = lazy(() => import('../layouts/MainLayout/MainLayout'))
const PrivateLayout = lazy(() => import('../layouts/PrivateLayout'))
const PublicLayout = lazy(() => import('../layouts/PublicLayout'))
const SignIn = lazy(() => import('../modules/SignIn'))
const EmailVerification = lazy(() => import('../modules/EmailVerification'))
// const Dashboard = lazy(() => import('../modules/Dashboard'))
const NotFound = lazy(() => import('../modules/NotFound'))
const Actions = lazy(() => import('../modules/Actions'))
const CreateNewPassword = lazy(() => import('../modules/CreateNewPassword'))
const ForgotPassword = lazy(() => import('../modules/ForgotPassword'))

interface IRouteAccess {
	children: any
	redirectTo: string
}

function PublicAccess({ children, redirectTo = '#' }: IRouteAccess) {
	const authContext = useOutletContext<IOutletAuth>()

	useEffect(() => {
		authContext?.setAccessType('public')
	}, [authContext])

	return authContext?.isAuth ? <Navigate to={redirectTo} /> : children
}

function PrivateAccess({ children, redirectTo = '#' }: IRouteAccess) {
	const authContext = useOutletContext<IOutletAuth>()

	useEffect(() => {
		authContext?.setAccessType('private')
	}, [authContext])

	return authContext?.isAuth ? children : <Navigate to={redirectTo} />
}

// ======== Earlier used will remove later ===================
// function CommonAccess({ children, redirectTo = '#' }: IRouteAccess) {
// 	const authContext = useOutletContext<IOutletAuth>()
// 	useEffect(() => {
// 		authContext?.setAccessType('common')
// 	}, [authContext])

// 	return children
// }
function CommonAccess({ children, redirectTo = '#' }: IRouteAccess) {
	const authContext = useOutletContext<IOutletAuth>()
	useEffect(() => {
		authContext?.setAccessType('common')
	}, [authContext])

	return authContext?.isAuth ? children : <Navigate to={redirectTo} />
}

export const taskTimerValue = {
	showTaskTimer: false,
	setShowTaskTimer: () => {},
	showTaskTrackingModal: false,
	setShowTaskTrackingModal: () => {},
	showTimerStopModal: false,
	setShowTimerStopModal: () => {},
	startTrackTime: 0,
	setStartTrackTime: () => {},
	endTrackTime: 0,
	setEndTrackTime: () => {},
	showTaskEditModal: false,
	setShowTaskEditModal: () => {},
	taskNote: '',
	setTaskNote: () => {},
	isStartTimer: false,
	setIsStartTimer: () => {},
	taskTimerDuration: 0,
	setTaskTimerDuration: () => {},
}

export interface ITaskTimerContext {
	showTaskTimer: boolean
	setShowTaskTimer: (showTaskTimer: boolean) => void
	showTaskTrackingModal: boolean
	setShowTaskTrackingModal: (showTaskTrackingModal: boolean) => void
	showTimerStopModal: boolean
	setShowTimerStopModal: (showTimerStopModal: boolean) => void
	startTrackTime: any
	setStartTrackTime: any
	endTrackTime: any
	setEndTrackTime: any
	showTaskEditModal: boolean
	setShowTaskEditModal: (showTaskEditModal: boolean) => void
	taskNote: any
	setTaskNote: (taskNote: any) => void
	isStartTimer: boolean
	setIsStartTimer: (isStartTimer: boolean) => void
	taskTimerDuration: any
	setTaskTimerDuration: (taskTimerDuration: any) => void
}
export const TaskTimerContext = React.createContext<ITaskTimerContext>(taskTimerValue)

export const onInsertChildren = (routesDetails: any) => {
	const details = transformedRoutes(routesDetails)
	const dynamicRoutesDetail = dynamicRoutesDetails(details)
	const fullRoutesData = [...dynamicRoutesDetail, ...genricRoutes]
	return fullRoutesData
}
const getComponent = (componentIdentifier: string): React.ReactNode => {
	const trimComponentName = onTrim(componentIdentifier)
	const component = componentDetailsObj[trimComponentName]
	const componentName = component ?? <NotFound />
	return componentName
}
const onGetElement = (componentName: string) => {
	return <Suspense fallback={<Loader />}>{getComponent(componentName)}</Suspense>
}
const onRoutesCreation = (routeObj: any) => {
	const {
		providerScreenChildren,
		clientScreen: { screen },
	} = routeObj
	if (screen) {
		const { slug, componentName } = screen || {}

		// const dummySlug = slug
		//  need to remove later
		// if (slug === 'screeningTemplateDashboard') {
		// 	// dummySlug = 'screeningTemplateDashboard/:templateId'
		// 	dummySlug = 'template-client-view/:id'
		// }
		if (providerScreenChildren && providerScreenChildren?.length > 0) {
			return {
				path: slug,
				// path: dummySlug,
				element: onGetElement(componentName),
				children: transformedRoutes(providerScreenChildren),
			}
		} else {
			return {
				path: slug,
				// path: dummySlug,
				element: onGetElement(componentName),
			}
		}
	}
}

const createCommonElement = (path: string, component: React.ReactNode) => {
	const elementObj = {
		path,
		element: <Suspense fallback={<Loader />}>{component}</Suspense>,
	}
	return elementObj
}

export const transformedRoutes = (routesDetails: any) => {
	const transformRoutes = []
	if (routesDetails && routesDetails?.length > 0) {
		const routes = routesDetails?.map(onRoutesCreation)
		transformRoutes.push(...routes)
	}
	return transformRoutes
}
// const moreSetting = morreSetting
// const sessions = sessions
const dynamicRoutesDetails = (details: any) => {
	const newArray = [...details]
	// const newArray = [...moreSetting, ...details]
	const dynamicRoute = [
		{
			element: (
				<PrivateAccess redirectTo={APP_ROUTES.SIGN_IN}>
					<PrivateLayout />
				</PrivateAccess>
			),
			children: [
				{
					path: '/',
					element: <Services />,
				},
				{
					element: (
						<Suspense fallback={<Loader />}>
							<CopyAccountLayout />
						</Suspense>
					),
					// children: details,
					children: newArray,
				},
			],
		},
	]
	return dynamicRoute
}
export const genricRoutes = [
	{
		element: (
			<PublicAccess redirectTo={'/'}>
				<PublicLayout />
			</PublicAccess>
		),

		children: [
			createCommonElement(APP_ROUTES?.SIGN_IN, <SignIn />),
			createCommonElement(APP_ROUTES?.CHANGE_PASSWORD, <CreateNewPassword />),
			createCommonElement(APP_ROUTES?.FORGOT_PASSWORD, <ForgotPassword />),
		],
	},
	{
		element: (
			<CommonAccess redirectTo={'/'}>
				<CommonLayout />
			</CommonAccess>
		),
		children: [
			createCommonElement(APP_ROUTES.ACTION, <Actions />),
			createCommonElement(APP_ROUTES.EMAIL_VERIFICATION, <EmailVerification />),
			{
				path: '*',
				element: (
					<CommonAccess redirectTo={'/'}>
						<NotFound />
					</CommonAccess>
				),
			},
		],
	},
]
const dynamicRouteDetails = dynamicRoutesDetails([])
export const fullRoutes = [...dynamicRouteDetails, ...genricRoutes]
/**
 * Array of custom route objects to be used in the application.
 * Each object represents a route with its corresponding layout and children.
 */
// export const dynamicRoutes: ICustomRouteObject[] = [
// 	{
// 		//@ts-ignore
// 		element: <MainLayout />,
// 		children: fullRoutes,
// 	},
// ]
export const onInsertChildrenInLayout = (childrenRoutes: ICustomRouteObject[]) => {
	const routes: ICustomRouteObject[] = [
		{
			element: <MainLayout />,
			children: childrenRoutes,
		},
	]
	return routes
}

export const onGetDynamicRoutes = () => {
	const dynamicRoutes = onInsertChildrenInLayout(fullRoutes)
	return dynamicRoutes
}

// const routesCopy: ICustomRouteObject[] = [
// 	{
// 		//@ts-ignore
// 		element: <MainLayout />,
// 		children: [...dynamicRouteDetails, ...genricRoutes],
// 	},
// ]
// export default routesCopy
