import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import {
	getCompleteIncompleMDEs,
	getDashboardCounts,
	getDashboardPartnerList,
	getEpidemiologistReportProgramListFilter,
	getLeastPatientMDE,
	getLeastPerformingPartners,
	getProgramClientFilter,
	getProgramPartnerList,
} from './Dashboard.service'
import { IAxiosResponseWithPagination } from 'types/entities'
import { IMemberParams } from 'modules/Members/Member/Member.interfaces'
import { IDashboardParams } from './Dashboard.interface'
import { SECTION_NAME } from 'const/common'
import { onGetCustomSectionDetail, onGetSectionName } from 'utils/common'
import { onCreateDynamicTilesData } from 'modules/AdminDashboard/Dashboard.hooks'

/**
 * hook to get count on dashboard stats
 */

interface IDashboardCountQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<any>>>,
		Partial<IDashboardParams> {}
export function useDashboardCounts({ program, ...rest }: IDashboardCountQueryProps) {
	return useQuery<any>(
		[ENDPOINTS.EPIDEMIOLOGIST_DASHBOARD_STATS, program],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getDashboardCounts(program, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!program,
			suspense: false,
		},
	)
}
/**
 * hook to get partner list based on completed and incompete MDEs
 */
interface IDashboardProgramPartnerListQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<any>>>,
		Partial<IDashboardParams> {}
export function useProgramPartnerList({
	program,
	...rest
}: IDashboardProgramPartnerListQueryProps) {
	return useQuery<any>(
		[ENDPOINTS.EPIDEMIOLOGIST_DASHBOARD_COMPLETED_INCOMPLETED, program],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getCompleteIncompleMDEs(program, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
			enabled: !!program,
		},
	)
}

/**
 * hook to get list of incomplete patient MDEs
 */
interface IDashboardLeastPatientMDEQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<any>>>,
		Partial<IDashboardParams> {}
export function useLeastPatientMDE({ program, ...rest }: IDashboardLeastPatientMDEQueryProps) {
	return useQuery<any>(
		[ENDPOINTS.EPIDEMIOLOGIST_DASHBOARD_PATIENT_INCOMPLETE_MDE, program],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getLeastPatientMDE(program, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!program,
		},
	)
}

/**
 * hook to get least performing partners
 */
interface IDashboardLeastPerformingPartnersQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<any>>>,
		Partial<IDashboardParams> {}
export function useLeastPerformingPartners({
	program,
	...rest
}: IDashboardLeastPerformingPartnersQueryProps) {
	return useQuery<any>(
		[ENDPOINTS.EPIDEMiOLOGIST_DASHBOARD_LEAST_PERFORMING_PARTNERS, program],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getLeastPerformingPartners(program, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!program,
		},
	)
}

/**
 * hook to get partner list
 */
interface IEpidemiologistPartnerQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<any>>>,
		Partial<IMemberParams> {}
export function usePartnerList({
	program,
	limit,
	page,
	companyName,
	...rest
}: IEpidemiologistPartnerQueryProps = {}) {
	return useQuery<any>(
		[ENDPOINTS.GET_HEALTHCARE_PARTNERS, program, limit, page, companyName],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getProgramPartnerList({ program, limit, page, companyName }, source)
		},
		{ enabled: !!program, keepPreviousData: true, refetchOnWindowFocus: false, suspense: false },
	)
}

export function useDashboardPartnerList({
	program,
	limit,
	page,
	companyName,
	search,
	...rest
}: IEpidemiologistPartnerQueryProps = {}) {
	return useQuery<any>(
		[ENDPOINTS.PARTNER_LIST, program, limit, page, companyName, search],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getDashboardPartnerList({ program, limit, page, companyName, search }, source)
		},
		{ enabled: !!program, keepPreviousData: true, refetchOnWindowFocus: false, suspense: false },
	)
}

/**
 * hook to get program list
 */
interface IEpidemiologistProgramQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<any>>>,
		Partial<IMemberParams> {}
export function useProgramFilterList({
	partner,
	limit,
	page,
	search,
	...rest
}: IEpidemiologistProgramQueryProps = {}) {
	return useQuery<any>(
		[ENDPOINTS.PROGRAM_FILTER_LIST, partner, limit, page, search],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getProgramClientFilter({ partner, limit, page, search }, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
		},
	)
}

export function useEpidemioLogistReportProgramFilterList({
	partner,
	limit,
	page,
	search,
	...rest
}: IEpidemiologistProgramQueryProps = {}) {
	return useQuery<any>(
		[ENDPOINTS.EPIDEMIOLOGIST_PROGRAM_FILTER, partner, limit, page, search],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getEpidemiologistReportProgramListFilter({ partner, limit, page, search }, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
		},
	)
}

export const getEpiDashboardSectionName = (screenSectionDetails: any) => {
	const leastPartnerSectioName = onGetSectionName(
		screenSectionDetails,
		SECTION_NAME?.LEAST_PERFORMING_PARTNERS,
	)
	const topCompletedMdesSectioName = onGetSectionName(
		screenSectionDetails,
		SECTION_NAME?.TOP_COMPLETE_MDES,
	)
	const leastInCompletedMdesSectioName = onGetSectionName(
		screenSectionDetails,
		SECTION_NAME?.LEAST_IN_COMPLETE_MDES,
	)
	const leastMembersInCompletedMdesSectioName = onGetSectionName(
		screenSectionDetails,
		SECTION_NAME?.LEAST_MEMBERS_WITH_INCOMPLETE_MDE,
	)
	return {
		leastPartnerSectioName,
		topCompletedMdesSectioName,
		leastInCompletedMdesSectioName,
		leastMembersInCompletedMdesSectioName,
	}
}

export const epiDashboardTilesDetails = (programManagerScreebDetails: any, data: any) => {
	const tileInfoIObj: any = {
		totalMdesCount: data?.data?.total ?? '',

		completedMdesCount: data?.data?.completed ? `${data?.data?.completed}%` : '',

		inCompletedMdesCount: data?.data?.inCompleted ? `${data?.data?.inCompleted}%` : '',
	}
	const getCustomSectionDetails = onGetCustomSectionDetail(
		programManagerScreebDetails,
		SECTION_NAME?.EPI_MDE_TILES,
	)

	const getTilesWithData = onCreateDynamicTilesData({ getCustomSectionDetails, data, tileInfoIObj })
	return getTilesWithData ?? []
}
