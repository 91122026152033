import { useState, useCallback, useEffect, useMemo, useContext } from 'react'
import { useUrlQuery } from './url'
import { debounce } from 'lodash'
import { NavigateFunction, useLocation, useOutletContext, useParams } from 'react-router-dom'
import { IOutletAuth, SESSION_TYPE } from 'types/auth'
import {
	buildSortParams,
	getLocalStorageDetails,
	onCreateEndpointsWithClientid,
	onGetCustomSectionDetail,
	onGetFilterColumnDetail,
	onGetScreenName,
	onParseJSON,
	onVerifyArray,
} from 'utils/common'

import { useItems, usePatients } from './react-query'
import { IGlobalCodeSelect, SelectOption } from 'types/entities'
import {
	useProviderList,
	useTrackingDevices,
} from 'modules/Programs/Program/Members/Member/Member.hooks'
import { useAllClients, useClients } from 'modules/Clients/Clients.hooks'
import {
	useGetPartnerListByClientInProvider,
	useProvidersForSchedules,
} from 'modules/Providers/Providers.hooks'
import { usePartnerClients } from 'modules/Partners/Partner/Partner.hooks'
import { useAllProviderClientList } from 'modules/Calender/Calendar.hooks'
import { useGlobalServices } from 'modules/Settings/Modules/Module/Module.hooks'
import { useGlobalCodeCategoryByCategoryCode } from 'modules/Settings/GlobalCodesCategorys/GlobalCodeCategory/GlobalCodeCategory.hooks'
import { RoleContext } from 'layouts/RoleLayout/RoleLayout'
import { DROPDOWN_LIMIT, LOCAL_STORAGE } from 'const/common'
import {
	useAssignALLModulesToProvider,
	useGetModuleForRole,
	useSpecificModulesAttribute,
} from 'modules/Settings/Roles/Role/Role.hooks'
import { useHealthConditions } from 'modules/Programs/Programs.hooks'
import { useSectionScreenWisePermission } from 'modules/Clients/AssignedServicesAndModules/AssignedServices/AssignedServices.hooks'
import {
	useCreateAssignModule,
	useServiceSpecificModules,
} from 'modules/Clients/AssignedServicesAndModules/AssignedModules/AssignModules.hooks'
import { errorNotify, successNotify } from 'components/Toaster/toster'
import { useDropdownTemplates, useTemplates } from 'modules/Templates/Templates.hooks'
import { IScreeningsParams } from 'modules/Questionnaires/Screenings/Screenings.interface'
import { useExistingScreenings } from 'modules/Questionnaires/Screenings/Screening/Screening.hooks'
import { useExistingQuestions } from 'modules/Questionnaires/Questionnaire/Questionnaire.hooks'
import { useMembers } from 'modules/Members/Members.hooks'
import { ISelectIdOption } from 'types/forms'
import { useQuery as useReactQuery } from '@tanstack/react-query'
import { ENDPOINTS } from 'const/endpoints'
import axios, { CancelTokenSource } from 'axios'
import { axios as serviceAxios } from 'services/axios'
import { IProgramParams } from 'modules/Programs/Program.interface'
import { commonMessages } from 'utils/messages'
import { useProviderClients } from 'modules/Providers/Provider/Provider.hooks'
import { useRoleAll } from 'modules/Settings/Roles/Roles.hooks'
import { useServicesClients } from 'modules/Dashboard copy/Services.hooks'
import { IPayloadForAssignedModules } from 'modules/Clients/AssignedServicesAndModules/AssignedModules/AssignModules.interface'
import { useMemberProviderTask } from 'modules/Members/Member/MemberTasks/MemberTask/MemberTask.hooks'
import { useProviders } from 'modules/Providers/Providers.hooks copy'
import { useSmbpPrograms } from 'modules/NonHealthCareMembers/NonHealthCareReports/NonHealthCareReports.hooks'
import { useMemberDropdownTemplates } from 'modules/Members/Member/MemberScreenings/MemberScreening/MemberScreening.hooks'
import { STEPPER_TITLE } from 'modules/TaskManagement/CreateAssignTask/CreateAssignTask.contsants'
import { useHealthCarePartners } from 'modules/Partners/Partners.hooks.copy'
import {
	useDashboardPartnerList,
	usePartnerList,
} from 'modules/Epidemiologist/EpidemiologistDashboard/Dashboard.hooks'
import { useProviderGroupsList } from 'modules/Groups/Group/Group.hooks'
import { useSlotContainerMembers } from 'modules/Calender/SlotDrawer/Slots.hooks'
import { useProvidersDoctorSpecialty } from 'modules/Referrals/Referral/Referral.hooks'
import { useConditionsAssignee } from 'modules/Members/Member/MemberConditions/MemberCondition/MemberCondition.hooks'
import { useProgramSuggestionSession } from 'modules/Programs/Program/Sessions/Session/Session.hooks'
import { useCareTeamAdd } from 'modules/Members/Member/MemberCareTeam/MemberCareTeam.hooks'

export function useShowPassword(): [boolean, () => void] {
	const [showPassword, setShowpassword] = useState(false)
	const toggleShowPassword = useCallback(() => {
		setShowpassword((value) => !value)
	}, [setShowpassword])
	return [showPassword, toggleShowPassword]
}

export function usePaginationHandlers(page = 1, total?: number) {
	const [params, setParams] = useUrlQuery<{ page: string; limit: string }>()

	const onPrev = useCallback(() => {
		if (+params?.page > 1) {
			let page = ''
			if (+page - 1 > 1) {
				page = String(+params?.page - 1)
			}
			setParams((_) => {
				return { page: page, limit: String(params.limit) }
			})
		}
	}, [params?.page, setParams])
	const onNext = useCallback(() => {
		const currentPage = params.page || page || 1
		if (total && +currentPage < +total) {
			setParams((_) => {
				return {
					page: String(params?.page ? +params?.page + 1 : 1 + 1),
					limit: String(params.limit),
				}
			})
		}
	}, [page, params.page, setParams, total])

	const onSetPageSize = useCallback(
		(limit: number) => {
			setParams((prev) => {
				return {
					...prev,
					page: String(1),
					limit: isNaN(limit) ? '' : String(limit),
				}
			})
		},
		[setParams],
	)
	const onGotoPage = useCallback(
		(page: number) => {
			if (total && +page && +page <= +total) {
				setParams((prev) => {
					return { ...prev, page: +page === +1 ? '' : String(page), limit: params?.limit }
				})
			}
		},
		[setParams, total],
	)
	return { onPrev, onNext, onSetPageSize, onGotoPage }
}

export function useNestedPaginationHandlers(page = 1, total?: number) {
	const [params, setParams] = useUrlQuery<{ innerPage: string; innerLimit: string }>()

	const onPrev = useCallback(() => {
		if (+params?.innerPage > 1) {
			let page = ''
			if (+page - 1 > 1) {
				page = String(+params?.innerPage - 1)
			}
			setParams((_) => {
				return { innerPage: page, innerLimit: String(params.innerLimit) }
			})
		}
	}, [params?.innerPage, setParams])
	const onNext = useCallback(() => {
		const currentPage = params.innerPage || page || 1
		if (total && +currentPage < +total) {
			setParams((_) => {
				return {
					innerPage: String(params?.innerPage ? +params?.innerPage + 1 : 1 + 1),
					innerLimit: String(params.innerLimit),
				}
			})
		}
	}, [page, params.innerPage, setParams, total])

	const onSetPageSize = useCallback(
		(limit: number) => {
			setParams((prev) => {
				return {
					...prev,
					innerPage: page === total ? String(1) : String(page),
					innerLimit: isNaN(limit) ? '' : String(limit),
				}
			})
		},
		[setParams],
	)
	const onGotoPage = useCallback(
		(page: number) => {
			if (total && +page && +page <= +total) {
				setParams((prev) => {
					return { ...prev, innerPage: +page === +1 ? '' : String(page), limit: params?.innerLimit }
				})
			}
		},
		[setParams, total],
	)
	return { onPrev, onNext, onSetPageSize, onGotoPage }
}

// Client
export function useClientsDynamicOptions({
	totalLimit = DROPDOWN_LIMIT.LIMIT,
	isEnabled = true,
}: {
	totalLimit?: number
	isEnabled?: boolean
}) {
	const [urlParams] = useUrlQuery<{ clientSearch: string }>()
	const [search, setSearch] = useState('')
	const { data, isLoading, isFetching, refetch } = useClients({
		isEnabled: isEnabled,
		search: urlParams?.clientSearch,
		suspense: false,
		limit: totalLimit,
	})
	return {
		search,
		makeSearch: useCallback((data: string) => {
			setSearch(data)
		}, []),
		isLoading,
		isFetching,
		options:
			useMemo(
				() => data?.data?.items?.map((i) => ({ label: i?.title, value: i?.id })),
				[data?.data.items],
			) || [],
		refetch: refetch,
		meta: data?.data,
	}
}

// Condtions

export function useHealthConditionsDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	sort,
	setPage,
	clientId,
}: {
	limit?: number
	page?: number
	sort?: string
	setPage: (key: number) => void
	clientId?: string
}) {
	const [search, setSearch] = useState('')
	const [healthContionSearchOptions, setHealthConditionSearchOptions] = useState<any>([])
	const [healthContionOptions, setHealthConditionOptions] = useState<any>([])
	const { data, isLoading, isFetching, refetch } = useHealthConditions({
		search: search,
		limit: limit,
		page: page,
		sort: sort,
		clientId,
	})

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleInputChange = debounceHandler(handleSearch)

	useEffect(() => {
		if (data?.data?.items && !search) {
			const existingValues = healthContionOptions?.map((option: any) => option.value)
			const newOptions = data?.data?.items
				.filter((item) => !existingValues.includes(item.id))
				.map((i) => ({
					...i,
					label: i?.condition,
					value: i?.id,
				}))
			setHealthConditionSearchOptions([])
			setHealthConditionOptions([...healthContionOptions, ...newOptions])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingValues = healthContionSearchOptions?.map((option: any) => option.value)
			const newOptions = data?.data?.items
				.filter((item) => !existingValues.includes(item.id))
				?.map((i) => ({
					...i,
					label: i?.condition,
					value: i?.id,
				}))
			setHealthConditionOptions([])
			setHealthConditionSearchOptions([...healthContionSearchOptions, ...newOptions])
		}
	}, [data?.data?.items])

	return {
		search,
		makeSearch: handleInputChange,
		isLoading,
		isFetching,
		options: search ? healthContionSearchOptions : healthContionOptions,
		refetch: refetch,
		meta: data?.data,
	}
}

// Existing Questions

export function useExistingQuestionsDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	sort,
	isEnabled = true,
	parent,
	screeningId,
}: {
	limit?: number
	page?: number
	sort?: string
	isEnabled: boolean
	parent: string
	screeningId: string
}) {
	const [search, setSearch] = useState('')
	const [existingQuestionsSearchOptions, setExistingQuestionsSearchOptions] = useState<any>([])
	const [existingQuestionsOptions, setExistingQuestionsOptions] = useState<any>([])
	const { data, isLoading, isFetching, refetch } = useExistingQuestions({
		parent,
		search: search,
		limit: limit,
		page: page,
		sort: sort,
		isEnabled,
		screeningId,
	})

	useEffect(() => {
		if (data?.data?.items && !search) {
			const existingValues = existingQuestionsOptions?.map((option: any) => option.value)
			const newOptions = data?.data?.items
				.filter((item) => !existingValues.includes(item?.id))
				.map((i) => ({
					label: i?.question?.title,
					value: i?.id,
				}))
			setExistingQuestionsSearchOptions([])
			setExistingQuestionsOptions((prevOptions: any) => [...prevOptions, ...newOptions])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingValues = existingQuestionsSearchOptions?.map((option: any) => option.value)
			const newOptions = data?.data?.items
				.filter((item) => !existingValues.includes(item?.id))
				?.map((i) => ({
					label: i?.question?.title,
					value: i?.id,
				}))
			setExistingQuestionsOptions([])
			setExistingQuestionsSearchOptions([...existingQuestionsSearchOptions, ...newOptions])
		}
	}, [data?.data?.items])

	return {
		search,
		makeSearch: useDebouncedHandler((data: string | undefined) => {
			setSearch(data || '')
		}, 2000),
		isLoading,
		isFetching,
		options: search ? existingQuestionsSearchOptions : existingQuestionsOptions,
		refetch: refetch,
		meta: data?.data,
	}
}

// Existing Questions

export function useTemplatesDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	sort,
	setPage,
	condition,
}: {
	limit?: number
	page?: number
	sort?: string
	setPage: (key: number) => void
	condition?: string | undefined
}) {
	const [search, setSearch] = useState('')
	const [templateSearchOptions, setTemplateSearchOptions] = useState<any>([])
	const [templateOptions, setTemplateOptions] = useState<any>([])
	const { data, isLoading, isFetching, refetch } = useDropdownTemplates({
		search: search,
		limit: limit,
		page: page,
		sort: sort,
		condition,
	})

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}
	const handleSearchInputChange = debounceHandler(handleSearch)
	useEffect(() => {
		if (data?.data?.items && !isFetching && !search) {
			const existingValues = templateOptions?.map((option: any) => option.value)
			const newOptions = data?.data?.items
				.filter((item: any) => !existingValues.includes(item?.id))
				.map((i: any) => ({
					label: i?.title,
					value: i?.templateCode,
					id: i?.id,
				}))
			setTemplateSearchOptions([])
			setTemplateOptions((prevOptions: any) => [...prevOptions, ...newOptions])
		}
	}, [data?.data?.items, isFetching])

	useEffect(() => {
		if (data?.data?.items && !isFetching && search) {
			const existingValues = templateSearchOptions?.map((option: any) => option.value)
			const newOptions = data?.data?.items
				.filter((item: any) => !existingValues.includes(item?.id))
				?.map((i: any) => ({
					label: i?.title,
					value: i?.templateCode,
					id: i?.id,
				}))
			setTemplateOptions([])
			setTemplateSearchOptions([...templateSearchOptions, ...newOptions])
		}
	}, [data?.data?.items, isFetching])
	return {
		search,
		makeSearch: handleSearchInputChange,
		isLoading,
		isFetching,
		options: search ? templateSearchOptions : templateOptions,
		refetch: refetch,
		meta: data?.data,
	}
}

export function useMemberTemplatesDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	sort,
	setPage,
	conditions,
	client,
	patient,
}: {
	limit?: number
	page?: number
	sort?: string
	setPage: (key: number) => void
	conditions?: string | undefined
	client: string | undefined
	patient: string | undefined
}) {
	const [search, setSearch] = useState('')
	const [templateSearchOptions, setTemplateSearchOptions] = useState<any>([])
	const [templateOptions, setTemplateOptions] = useState<any>([])
	const { data, isLoading, isFetching, refetch } = useMemberDropdownTemplates({
		search: search,
		limit: limit,
		page: page,
		sort: sort,
		conditions,
		client,
		patient,
	})

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}
	const handleSearchInputChange = debounceHandler(handleSearch)
	useEffect(() => {
		if (data?.data?.items && !isFetching && !search) {
			const existingValues = templateOptions?.map((option: any) => option.value)
			const newOptions = data?.data?.items
				.filter((item: any) => !existingValues.includes(item?.id))
				.map((i: any) => ({
					label: `${i?.title} - ${i?.templateCode}`,
					value: i?.id,
				}))
			setTemplateSearchOptions([])
			setTemplateOptions((prevOptions: any) => [...prevOptions, ...newOptions])
		}
	}, [data?.data?.items, isFetching])

	useEffect(() => {
		if (data?.data?.items && !isFetching && search) {
			const existingValues = templateSearchOptions?.map((option: any) => option.value)
			const newOptions = data?.data?.items
				.filter((item: any) => !existingValues.includes(item?.id))
				?.map((i: any) => ({
					label: `${i?.title} - ${i?.templateCode}`,
					value: i?.id,
				}))
			setTemplateOptions([])
			setTemplateSearchOptions([...templateSearchOptions, ...newOptions])
		}
	}, [data?.data?.items, isFetching])

	return {
		search,
		makeSearch: handleSearchInputChange,
		isLoading,
		isFetching,
		options: search ? templateSearchOptions : templateOptions,
		refetch: refetch,
		meta: data?.data,
	}
}

// Members Dropdown

export function useMembersDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	sort,
	condition,
	setPage,
}: {
	limit?: number
	page?: number
	sort?: string
	setPage: (key: number) => void
	condition?: string
}) {
	const [search, setSearch] = useState('')
	const [searchOptions, setSearchOptions] = useState<any>([])
	const [options, setOptions] = useState<any>([])
	const { data, isLoading, isFetching, refetch } = useMembers({
		search: search,
		limit: limit,
		page: page,
		sort: sort,
		healthCondition: condition,
	})

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleSearchInputChange = debounceHandler(handleSearch)

	useEffect(() => {
		if (data?.data?.items && !search) {
			const existingValues = options?.map((option: any) => option.value)
			const newOptions = data?.data?.items
				.filter((item) => !existingValues.includes(item?.id))
				.map((i) => ({
					label: i?.humanName?.text,
					value: i?.id,
				}))
			setSearchOptions([])
			setOptions((prevOptions: any) => [...prevOptions, ...newOptions])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingValues = searchOptions?.map((option: any) => option.value)
			const newOptions = data?.data?.items
				.filter((item) => !existingValues.includes(item?.id))
				?.map((i) => ({
					label: i?.humanName?.text,
					value: i?.id,
				}))
			setOptions([])
			setSearchOptions([...searchOptions, ...newOptions])
		}
	}, [data?.data?.items])

	return {
		search,
		makeSearch: handleSearchInputChange,
		isLoading,
		isFetching,
		options: search ? searchOptions : options,
		refetch: refetch,
		meta: data?.data,
	}
}

// Existing Screens

export function useExistingScreeeningDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	sort,
	isEnabled = true,
	setPage,
}: {
	limit?: number
	page?: number
	sort?: string
	isEnabled: boolean
	setPage: (key: number) => void
}) {
	const [urlQuery] = useUrlQuery<IScreeningsParams>()
	const [search, setSearch] = useState('')
	const template = urlQuery.template
	const [existingScreeningSearchOptions, setExistingScreeningSearchOptions] = useState<any>([])
	const [existingScreeningOptions, setExistingScreeningOptions] = useState<any>([])
	const { data, isLoading, isFetching, refetch } = useExistingScreenings({
		template,
		search: search,
		limit: limit,
		page: page,
		sort: sort,
		isEnabled,
	})

	useEffect(() => {
		if (data?.data?.items && !search) {
			const existingValues = existingScreeningOptions?.map((option: any) => option.value)
			const newOptions = data?.data?.items
				.filter((item: any) => !existingValues.includes(item?.id))
				.map((i: any) => ({
					label: `${i?.title} - ${i?.questionTemplate?.templateCode}`,
					value: i?.id,
				}))
			setExistingScreeningSearchOptions([])
			setExistingScreeningOptions((prevOptions: any) => [...prevOptions, ...newOptions])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingValues = existingScreeningSearchOptions?.map((option: any) => option.value)
			const newOptions = data?.data?.items
				.filter((item: any) => !existingValues.includes(item?.id))
				?.map((i: any) => ({
					label: `${i?.title} - ${i?.questionTemplate?.templateCode}`,
					value: i?.id,
				}))
			setExistingScreeningOptions([])
			setExistingScreeningSearchOptions([...existingScreeningSearchOptions, ...newOptions])
		}
	}, [data?.data?.items])
	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleSearchInputChange = debounceHandler(handleSearch)
	return {
		search,
		makeSearch: handleSearchInputChange,
		isLoading,
		isFetching,
		options: search ? existingScreeningSearchOptions : existingScreeningOptions,
		refetch: refetch,
		meta: data?.data,
	}
}

export function useClientsProviderDynamicOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	isEnabled = true,
	page,
	sort,
	setPage,
}: {
	limit?: number
	isEnabled?: boolean
	page?: number
	sort?: string
	setPage: (key: number) => void
}) {
	const [search, setSearch] = useState('')
	const [clientOptions, setClientOptions] = useState<any>([])
	const [clientSearchOptions, setClientSearchOptions] = useState<any>([])
	const { data, isLoading, isFetching, refetch } = useClients({
		isEnabled: isEnabled,
		search: search,
		suspense: false,
		limit: limit,
		page: page,
		sort: sort,
	})

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleSearchClientInputChange = debounceHandler(handleSearch)

	useEffect(() => {
		if (data?.data?.items && !search) {
			const existingClientValues = clientOptions?.map((option: any) => option.value)
			const newClientOptions = data?.data?.items
				.filter((item) => !existingClientValues.includes(item.id))
				.map((i) => ({
					...i,
					label: i?.title,
					value: i?.id,
				}))
			setClientSearchOptions([])
			setClientOptions((prevOptions: any) => [...prevOptions, ...newClientOptions])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingClientValues = clientSearchOptions?.map((option: any) => option.value)
			const newClientOptions = data?.data?.items
				?.filter((item) => !existingClientValues.includes(item.id))
				?.map((i) => ({
					...i,
					label: i?.title,
					value: i?.id,
				}))
			setClientOptions([])
			setClientSearchOptions([...clientSearchOptions, ...newClientOptions])
		}
	}, [data?.data?.items])
	return {
		search,
		makeSearch: handleSearchClientInputChange,
		isLoading,
		isFetching,
		options: search ? clientSearchOptions : clientOptions,
		refetch: refetch,
		meta: data?.data,
	}
}
// Partner Common

export function usePartnerClientsDynamicOptions({
	id,
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	setPage,
	isEnabled = true,
}: {
	id?: string
	limit?: number
	page?: number
	setPage: (key: number) => void
	isEnabled?: boolean
}) {
	const [search, setSearch] = useState('')
	const [partnerOptions, setPartnerOptions] = useState<any>([])
	const [partnerSearchOptions, setPartnerSearchOptions] = useState<any>([])
	const { data, isLoading, isFetching, refetch } = useGetPartnerListByClientInProvider({
		id: id,
		limit: limit,
		page: page,
		search,
		isEnabled,
	})

	useEffect(() => {
		let labelValue: any = []
		if (data?.data?.items && !search) {
			const existingValues = partnerOptions?.map((option: any) => option.value)
			labelValue = data?.data?.items
				?.filter((item) => !existingValues.includes(item.id))
				?.map((i) => ({
					...i,
					label: i?.companyName,
					value: i?.id,
				}))
			setPartnerSearchOptions([])
			setPartnerOptions([...partnerOptions, ...labelValue])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingValues = partnerSearchOptions?.map((option: any) => option.value)
			const searchedData = data?.data?.items
				?.filter((item) => !existingValues.includes(item.id))
				?.map((i) => ({
					...i,
					label: i?.companyName,
					value: i?.id,
				}))
			setPartnerOptions([])
			setPartnerSearchOptions([...partnerSearchOptions, ...searchedData])
		}
	}, [data?.data?.items])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleSearchInputChange = debounceHandler(handleSearch)

	return {
		search,
		makeSearch: handleSearchInputChange,
		isLoading,
		isFetching,
		setSearch,
		options: search ? partnerSearchOptions : partnerOptions,
		refetch: refetch,
		meta: data?.data,
	}
}

export function usePartnerDropdownOptions({
	id,
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	setPage,
	isEnabled = true,
}: {
	id: string
	limit?: number
	page?: number
	isEnabled?: boolean

	setPage: (key: number) => void
}) {
	const [search, setSearch] = useState('')
	const [options, setOptions] = useState<any>([])
	const [searchOptions, setSearchOptions] = useState<any>([])
	const { data, isLoading, isFetching, refetch } = useGetPartnerListByClientInProvider({
		id: id,
		limit: limit,
		page: page,
		search,
		isEnabled,
	})

	useEffect(() => {
		if (data?.data?.items && !search) {
			const existingValues = options?.map((option: any) => option.value)
			const newClientOptions = data?.data?.items
				.filter((item) => !existingValues.includes(item.id))
				.map((i) => ({
					...i,
					label: i?.companyName,
					value: i?.id,
				}))
			setSearchOptions([])
			setOptions((prevOptions: any) => [...prevOptions, ...newClientOptions])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingValues = searchOptions?.map((option: any) => option.value)
			const newOptions = data?.data?.items
				?.filter((item) => !existingValues.includes(item.id))
				?.map((i) => ({
					...i,
					label: i?.title,
					value: i?.id,
				}))
			setOptions([])
			setSearchOptions([...searchOptions, ...newOptions])
		}
	}, [data?.data?.items])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleSearchInputChange = debounceHandler(handleSearch)

	return {
		search,
		makeSearch: handleSearchInputChange,
		isLoading,
		isFetching,
		setSearch,
		options: search ? searchOptions : options,
		refetch: refetch,
		meta: data?.data,
	}
}

export function useAllRolesClientsDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	sort,
	isEnabled = true,
	setPage,
}: {
	limit?: number
	page?: number
	sort?: string
	isEnabled?: boolean
	setPage: (key: number) => void
}) {
	const [search, setSearch] = useState('')
	const [clientOptions, setClientOptions] = useState<any>([])
	const [clientSearchOptions, setClientSearchOptions] = useState<any>([])
	const { data, isFetching, refetch } = usePartnerClients({ limit, page, search, sort, isEnabled })
	useEffect(() => {
		let labelValue: any = []
		if (data?.data?.items && !search) {
			const existingValues = clientOptions?.map((option: any) => option.value)
			labelValue = data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((i: any) => {
					return {
						...i,
						label: i?.title,
						value: i?.id,
					}
				})
			setClientSearchOptions([])
			setClientOptions([...clientOptions, ...labelValue])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingValues = clientSearchOptions?.map((option: any) => option.value)
			const items = data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((i: any) => {
					return {
						...i,
						label: i?.title,
						value: i?.id,
					}
				})
			setClientOptions([])
			setClientSearchOptions([...clientSearchOptions, ...items])
		}
	}, [data?.data?.items])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleSearchInputChange = debounceHandler(handleSearch)

	return {
		search,
		makeSearch: handleSearchInputChange,
		isFetching,
		options: search ? clientSearchOptions : clientOptions,
		refetch: refetch,
		meta: data?.data,
	}
}

export function useProvidersDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	sort,
	isEnabled = true,
	setPage,
	partnerFilter,
	partner,
}: {
	limit?: number
	page?: number
	sort?: string
	isEnabled?: boolean
	setPage: (key: number) => void
	partnerFilter?: string[] | []
	partner?: string
}) {
	const [search, setSearch] = useState('')
	const [options, setOptions] = useState<any>([])
	const [searchOptions, setSearchOptions] = useState<any>([])
	const { data, isFetching, refetch } = useProvidersForSchedules({
		limit,
		page,
		search,
		sort,
		isEnabled,
		partner,
		partnerFilter,
	})

	useEffect(() => {
		if (partner) {
			setOptions([])
			setSearchOptions([])
		}
	}, [partner])

	useEffect(() => {
		let labelValue: any = []
		if (data?.data?.items && !search) {
			const existingValues = options?.map((option: any) => option.value)
			labelValue = data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((i: any) => {
					return {
						label: i?.humanName?.text,
						value: i?.id,
					}
				})
			setSearchOptions([])
			setOptions([...options, ...labelValue])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingValues = searchOptions?.map((option: any) => option.value)
			const items = data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((i: any) => {
					return {
						label: i?.humanName?.text,
						value: i?.id,
					}
				})
			setOptions([])
			setSearchOptions([...searchOptions, ...items])
		}
	}, [data?.data?.items])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleSearchInputChange = debounceHandler(handleSearch)

	return {
		search,
		makeSearch: handleSearchInputChange,
		isFetching,
		options: search ? searchOptions : options,
		refetch: refetch,
		meta: data?.data,
	}
}

// Care Team In member profile

export function useCareTeamOptions({
	id,
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	setPage,
	isSubmitting,
}: {
	id?: string
	limit?: number
	page?: number
	setPage: (key: number) => void
	isSubmitting?: boolean
}) {
	const [search, setSearch] = useState<string>('')
	const [careTeamOptions, setCareTeamOptions] = useState<any>([])
	const [careTeamSearchOptions, setCareTeamSearchOptions] = useState<any>([])

	const { data, isLoading, isFetching, refetch } = useCareTeamAdd({
		id,
		clientId: getClientDetails()?.clientId,
		limit,
		page,
		search,
	})

	// Function to transform data into options
	const transformData = (items: any) =>
		items.map((item: any) => ({
			...item,
			label: item.humanName.text,
			value: item.id,
		}))

	useEffect(() => {
		if (data?.data?.items) {
			const items = data.data.items
			const existingValues = new Set(careTeamOptions?.map((option: any) => option.value))

			if (!search) {
				const newOptions = transformData(items).filter(
					(item: any) => !existingValues.has(item.value),
				)
				setCareTeamOptions((prev: any) => [...prev, ...newOptions])
				setCareTeamSearchOptions([])
			} else {
				const searchResults = transformData(items).filter(
					(item: any) => !existingValues.has(item.value),
				)
				setCareTeamSearchOptions((prev: any) => [...prev, ...searchResults])
				setCareTeamOptions([])
			}
		}
	}, [data?.data?.items, search])

	useEffect(() => {
		if (isSubmitting) {
			setCareTeamOptions([])
			setSearch('')
			setPage(1)
		}
	}, [isSubmitting])

	const handleSearch = useCallback(
		(query: string | undefined) => {
			setSearch(query || '')
			setPage(1)
		},
		[setPage],
	)

	const handleSearchInputChange = useCallback(debounce(handleSearch, 300), [handleSearch])

	return {
		search,
		makeSearch: handleSearchInputChange,
		isLoading,
		isFetching,
		setSearch,
		options: search ? careTeamSearchOptions : careTeamOptions,
		refetch,
		meta: data?.data,
	}
}

export function debounceHandler(cb: any, delay = 1000) {
	let timeout: any
	return (...args: any) => {
		clearTimeout(timeout)
		timeout = setTimeout(() => {
			cb(...args)
		}, delay)
	}
}

export function useClientsAllDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	setPage,
	templateCode,
	isEnabled,
	isAssign,
}: {
	limit?: number
	page?: number
	templateCode?: string
	isEnabled?: boolean
	setPage: (key: number) => void
	isAssign?: string
}) {
	const [clientOptions, setClientOptions] = useState<any>([])
	const [clientSearchOptions, setClientSearchOptions] = useState<any>([])
	const [search, setSearch] = useState('')
	const { data, isFetching, refetch } = usePartnerClients({
		limit,
		page,
		search,
		templateCode,
		isEnabled,
		isAssign,
	})
	useEffect(() => {
		if (data?.data?.items && !search) {
			const existingClientValues = clientOptions?.map((option: any) => option.value)
			const newClientOptions = data?.data?.items
				.filter((item: any) => !existingClientValues.includes(item.id))
				.map((i: any) => ({
					...i,
					label: i?.title,
					value: i?.id,
				}))
			setClientSearchOptions([])
			setClientOptions([...clientOptions, ...newClientOptions])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingClientValues = clientSearchOptions?.map((option: any) => option.value)
			const newClientOptions = data?.data?.items
				.filter((item: any) => !existingClientValues.includes(item.id))
				.map((i: any) => ({
					...i,
					label: i?.title,
					value: i?.id,
				}))
			setClientOptions([])
			setClientSearchOptions([...clientSearchOptions, ...newClientOptions])
		}
	}, [data?.data?.items])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}
	const handleSearchOptions = debounceHandler(handleSearch)
	return {
		search,
		makeSearch: handleSearchOptions,
		isFetching,
		options: search ? clientSearchOptions : clientOptions,
		meta: data?.data,
		refetch: refetch,
	}
}

// Services Client API

export function useServicesClientsAllDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	setPage,
	templateCode,
	isEnabled,
	isAssign,
}: {
	limit?: number
	page?: number
	templateCode?: string
	isEnabled?: boolean
	setPage: (key: number) => void
	isAssign?: string
}) {
	const [clientOptions, setClientOptions] = useState<any>([])
	const [clientSearchOptions, setClientSearchOptions] = useState<any>([])
	const [search, setSearch] = useState('')
	const { data, isFetching, refetch } = useServicesClients({
		limit,
		page,
		search,
		isEnabled,
		isAssign,
		templateCode,
	})
	useEffect(() => {
		if (data?.data?.items && !search) {
			const existingClientValues = clientOptions?.map((option: any) => option.value)
			const newClientOptions = data?.data?.items
				.filter((item: any) => !existingClientValues.includes(item.id))
				.map((i: any) => ({
					...i,
					label: i?.title,
					value: i?.id,
				}))
			setClientSearchOptions([])
			setClientOptions([...clientOptions, ...newClientOptions])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingClientValues = clientSearchOptions?.map((option: any) => option.value)
			const newClientOptions = data?.data?.items
				.filter((item: any) => !existingClientValues.includes(item.id))
				.map((i: any) => ({
					...i,
					label: i?.title,
					value: i?.id,
				}))
			setClientOptions([])
			setClientSearchOptions([...clientSearchOptions, ...newClientOptions])
		}
	}, [data?.data?.items])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}
	const handleSearchOptions = debounceHandler(handleSearch)
	return {
		search,
		makeSearch: handleSearchOptions,
		isFetching,
		options: search ? clientSearchOptions : clientOptions,
		meta: data?.data,
		refetch: refetch,
	}
}

// This Function is get Data of API ROles Based

export function useProviderRoleAllDropdownOptions({
	client,
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	setPage,
}: {
	client: string
	limit?: number
	page?: number
	setPage: (key: number) => void
}) {
	const [roleOptions, setRoleOptions] = useState<any>([])
	const [roleSearchOptions, setRoleSearchOptions] = useState<any>([])
	const [search, setSearch] = useState('')
	const { data, isFetching, refetch } = useRoleAll({
		client,
		limit,
		page,
		search,
	})
	useEffect(() => {
		if (data?.data?.items && !search) {
			const existingRoleValues = roleOptions?.map((option: any) => option.value)
			const newRoleOptions = data?.data?.items
				.filter((item: any) => !existingRoleValues.includes(item.id))
				.map((i: any, index: any) => ({
					...i,
					label: i?.roleName,
					value: i?.id,
					slug: i?.slug,
				}))
			setRoleSearchOptions([])
			setRoleOptions([...roleOptions, ...newRoleOptions])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingRoleValues = roleSearchOptions?.map((option: any) => option.value)
			const newRoleOptions = data?.data?.items
				.filter((item: any) => !existingRoleValues.includes(item.id))
				.map((i: any) => ({
					...i,
					label: i?.roleName,
					value: i?.id,
					slug: i?.slug,
				}))
			setRoleOptions([])
			setRoleSearchOptions([...roleSearchOptions, ...newRoleOptions])
		}
	}, [data?.data?.items])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage && setPage(1)
	}

	const handleSearchOptions = debounceHandler(handleSearch)
	return {
		search,
		makeSearch: handleSearchOptions,
		isFetching,
		options: search ? roleSearchOptions : roleOptions,
		meta: data?.data,
		refetch: refetch,
	}
}

// This Function is used to Integrate API of Providers

export function useClientsForRolesOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	setPage,
	isEnabled,
}: {
	limit?: number
	page?: number
	templateCode?: string
	isEnabled?: boolean
	setPage: (key: number) => void
}) {
	const [clientOptions, setClientOptions] = useState<any>([])
	const [clientSearchOptions, setClientSearchOptions] = useState<any>([])
	const [search, setSearch] = useState('')
	const { data, isFetching, refetch } = useProviderClients({
		limit,
		page,
		search,
		isEnabled,
	})
	useEffect(() => {
		if (data?.data?.items && !search) {
			const existingClientValues = clientOptions?.map((option: any) => option.value)
			const newClientOptions = data?.data?.items
				.filter((item: any) => !existingClientValues.includes(item.id))
				.map((i: any) => ({
					...i,
					label: i?.title,
					value: i?.id,
					isDefaultClient: i?.isDefaultClient,
				}))
			setClientSearchOptions([])
			setClientOptions([...clientOptions, ...newClientOptions])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingClientValues = clientSearchOptions?.map((option: any) => option.value)
			const newClientOptions = data?.data?.items
				.filter((item: any) => !existingClientValues.includes(item.id))
				.map((i: any) => ({
					...i,
					label: i?.title,
					value: i?.id,
					isDefaultClient: i?.isDefaultClient,
				}))
			setClientOptions([])
			setClientSearchOptions([...clientSearchOptions, ...newClientOptions])
		}
	}, [data?.data?.items])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleSearchOptions = debounceHandler(handleSearch)
	return {
		search,
		makeSearch: handleSearchOptions,
		isFetching,
		options: search ? clientSearchOptions : clientOptions,
		meta: data?.data,
		refetch: refetch,
	}
}

export function useAllProviderCalender(params: any) {
	const [search, setSearch] = useState('')
	const { data, isFetching } = useAllProviderClientList({ search, ...params })
	return {
		search,
		makeSearch: useCallback((data: string) => {
			setSearch(data)
		}, []),
		isFetching,
		options:
			useMemo(
				() =>
					data?.data.items
						?.filter((item) => item?.humanName?.text)
						.map((i: any) => ({ label: i?.humanName?.text, value: i.id })),
				[data?.data.items],
			) || [],
	}
}
export function useDebouncedHandler<T>(handler: (params?: T) => any | void, duration = 300) {
	const debouncedHandler = useMemo(() => {
		return debounce(handler, duration)
	}, [duration, handler])

	useEffect(() => {
		return () => {
			debouncedHandler.cancel()
		}
	}, [debouncedHandler])

	return debouncedHandler
}

// Hook
export function useOnClickOutside(
	ref: React.RefObject<HTMLDivElement> | undefined,
	handler: (param: any) => void,
) {
	useEffect(
		() => {
			const listener = (event: any) => {
				// Do nothing if clicking ref's element or descendent elements
				if (!ref?.current || ref?.current.contains(event.target)) {
					return
				}
				handler(event)
			}
			document.addEventListener('mousedown', listener)
			document.addEventListener('touchstart', listener)
			return () => {
				document.removeEventListener('mousedown', listener)
				document.removeEventListener('touchstart', listener)
			}
		},
		// Add ref and handler to effect dependencies
		// It's worth noting that because passed in handler is a new ...
		// ... function on every render that will cause this effect ...
		// ... callback/cleanup to run every render. It's not a big deal ...
		// ... but to optimize you can wrap handler in useCallback before ...
		// ... passing it into this hook.
		[ref, handler],
	)
}

export function useAuthFromContext() {
	const context = useOutletContext<IOutletAuth>()
	return context
}

export function useMyProfile() {
	const context = useOutletContext<IOutletAuth>()
	return context?.profile
}

export function useServiceName() {
	const context = useOutletContext<IOutletAuth>()
	const serviceNameTitle = convertToTitleCase(context?.serviceName)
	return serviceNameTitle
}
const convertToTitleCase = (serviceName: string) => {
	return serviceName?.toLowerCase()?.replace(/\b\w/g, function (match) {
		return match?.toUpperCase()
	})
}
const filterScreens = (screens: any) => {
	let screenDetails: any = []

	if (screens && screens?.length > 0) {
		onVerifyArray(screens)?.map((screenObj: any) => {
			// const { children } = screenObj?.clientScreen?.screen
			if (screenObj?.providerScreenChildren && screenObj?.providerScreenChildren?.length > 0) {
				screenDetails?.push(screenObj)
				const screenChildDetail = filterScreens(screenObj?.providerScreenChildren)
				screenDetails = [...screenDetails, ...screenChildDetail]
			} else {
				screenDetails?.push(screenObj)
			}
		})
	}
	return screenDetails
}

// export function useGetRouteDetails() {
// 	const context = useOutletContext<IOutletAuth>()
// 	const servicePermissionDetails = context?.servicePermission
// 	const modulePathDetails: any = []
// 	servicePermissionDetails?.providerServiceModules?.forEach((clientModuleObj: any) => {
// 		const moduleSlug = `/${clientModuleObj?.providerModule?.clientModules?.modules?.slug}`
// 		modulePathDetails?.push({
// 			slug: moduleSlug,
// 		})
// 	})
// 	if (modulePathDetails?.length > 0) {
// 		return modulePathDetails
// 	} else {
// 		return []
// 	}
// }

export function useServicePermission() {
	const context = useOutletContext<IOutletAuth>()
	return context?.servicePermission
}

export function useGlobalCode() {
	const context = useOutletContext<IOutletAuth>()
	return context?.globalCodes
}

//  Function to get Module Details
export const onGetModuleDetail = (moduleName: string) => {
	const servicePermissionDetails = useServicePermission()
	const moduleData = servicePermissionDetails?.providerServiceModules?.find((moduleObj: any) => {
		return moduleObj?.providerModule?.clientModules?.modules?.moduleName === moduleName
	})
	return moduleData
}

//  Replace Function to get Module Details
export const onGetModuleDetailWithSlug = (moduleSlug: string) => {
	const servicePermissionDetails = useServicePermission()
	const moduleData = servicePermissionDetails?.providerServiceModules?.find((moduleObj: any) => {
		return moduleObj?.providerModule?.clientModules?.modules?.slug === moduleSlug
	})
	return moduleData
}

//  Function to check is Module Available in permission
export const useCheckModulePermission = (moduleName: string) => {
	const servicePermissionDetails = useServicePermission()
	const isModuleAvailable = servicePermissionDetails?.providerServiceModules?.some(
		(moduleObj: any) => {
			return moduleObj?.providerModule?.clientModules?.modules?.moduleName === moduleName
		},
	)
	return isModuleAvailable
}

//  Function to check is Module Available in permission
export const useCheckModuleLevelPermission = (moduleSlugName: string) => {
	const servicePermissionDetails = useServicePermission()
	const isModuleAvailable = servicePermissionDetails?.providerServiceModules?.some(
		(moduleObj: any) => {
			return moduleObj?.providerModule?.clientModules?.modules?.slug === moduleSlugName
		},
	)
	return isModuleAvailable
}

// Module wise permission custom hook to get permission details module wise
export function usePermission(moduleName: string) {
	const moduleDetails = onGetModuleDetail(moduleName)
	const providerModuleDetails = moduleDetails?.providerModule?.providerModulesAttribute?.map(
		(value: any) => {
			return {
				[value?.clientModulesAttribute?.modulesAttribute?.attributes?.slug
					?.replace(/ /g, '')
					?.toLowerCase()]: value?.clientModulesAttribute?.modulesAttribute?.attributes?.slug,
			}
		},
	)
	const attributeDetails = providerModuleDetails?.reduce((result: any, currentObject: any) => {
		return { ...result, ...currentObject }
	}, {})
	return attributeDetails
}

// export function useCheckScreen(screenName: string) {
// 	const context = useOutletContext<IOutletAuth>()
// 	const servicePermissionDetails = context?.servicePermission
// 	const moduleDetails = servicePermissionDetails?.providerModule
// 		.find((item: any) => item?.clientModules?.displayName === screenName)
// 		?.providerAttributes?.map((value: any) => {
// 			return {
// 				[value?.attribute?.attributeName]: value?.attribute?.isActive,
// 			}
// 		})
// 		?.reduce((result: any, currentObject: any) => {
// 			return { ...result, ...currentObject }
// 		}, {})

// 	return moduleDetails
// }

export function useGetAllScreens() {
	const servicePermissionDetails = useServicePermission()
	const screenFilter = servicePermissionDetails?.providerServiceModules?.map(
		(clientModuleObj: any) => {
			// return clientModuleObj?.providerModule?.providerScreens
			//  new code modified
			const providerScreens = clientModuleObj?.providerModule?.providerScreens || []
			const childScreens =
				clientModuleObj?.providerModule?.children?.map(
					(child: any) => child.providerScreens || [],
				) || []

			return [providerScreens, ...childScreens]
		},
	)
	const allModulesScreen = screenFilter?.flat(Infinity)
	if (allModulesScreen) {
		const fullScreenDetails = filterScreens(onVerifyArray(allModulesScreen))
		return fullScreenDetails
	} else {
		return []
	}
}

//  Custom hook to check is screen Available
export function useCheckScreenPermission(screenSlug: string) {
	const allScreensDetails = useGetAllScreens()
	const isScreenAvailable = allScreensDetails?.some(
		(screenObj: any) => screenObj?.clientScreen?.screen?.slug === screenSlug,
	)
	return isScreenAvailable
}
// //  Custom hook to check is screen Available
// export function useCheckScreenPermission(screenName: string) {
// 	const allScreensDetails = useGetAllScreens()
// 	const isScreenAvailable = allScreensDetails?.some(
// 		(screenObj: any) => screenObj?.clientScreen?.screen?.screenName === screenName,
// 	)
// 	return isScreenAvailable
// }

// export function useGetScreenDetails(screenName: string) {
// 	const allScreensDetails = useGetAllScreens()
// 	const filteredData = allScreensDetails.map((screenObj: any) => ({
// 		screenName: screenObj?.clientScreen?.screen?.screenName,
// 		slug: screenObj?.clientScreen?.screen?.slug,
// 	}))
// 	console.log(filteredData, 'filteredScreen')

// 	const screenDetail = allScreensDetails?.find((screenObj: any) => {
// 		return screenObj?.clientScreen?.screen?.screenName === screenName
// 	})
// 	return screenDetail
// }

export function useGetScreenDetails(screenSlug: string) {
	const allScreensDetails = useGetAllScreens()
	// const filteredData = allScreensDetails.map((screenObj: any) => ({
	// 	screenName: screenObj?.clientScreen?.screen?.screenName,
	// 	slug: screenObj?.clientScreen?.screen?.slug,
	// }))
	const screenDetail = allScreensDetails?.find((screenObj: any) => {
		return screenObj?.clientScreen?.screen?.slug === screenSlug
	})
	return screenDetail
}

/**
 * Uses screen details
 * @param screenName
 * @returns
 */
export function useScreenDetails(screenName: string) {
	const screenData = useGetScreenDetails(screenName)
	const clientId = getClientDetails()?.clientId
	const screenSectionPermission = useSectionScreenWisePermission({
		screenId: screenData?.id,
		clientId: clientId,
	})
	const providerScreenDetail = screenSectionPermission?.data?.data
	const allScreenSection = providerScreenDetail?.providerScreenSections
		?.map((section: any) => {
			const filterNameValue = section?.providerSection?.clientSection?.slug
			return {
				[filterNameValue?.replace(/ /g, '')?.toLowerCase()]: section?.providerSection,
			}
		})
		?.reduce((result: any, currentObject: any) => {
			return { ...result, ...currentObject }
		}, {})
	const allowedSectionDetail = useAllowedSection(providerScreenDetail)
	const displayScreenName = onGetScreenName(providerScreenDetail)
	const screenDetailObj = {
		providerScreenDetail,
		allowedSectionDetail: {
			...allowedSectionDetail,
			isScreenFetching: screenSectionPermission?.isFetching,
		},
		allScreenSection,
		displayScreenName,
	}
	return screenDetailObj
}

export function useAllowedSection(providerScreenSection: any) {
	const allowedSection = providerScreenSection?.providerScreenSections
		?.map((section: any) => {
			const filterNameValue = section?.providerSection?.clientSection?.slug
			return {
				[filterNameValue?.replace(/ /g, '')?.toLowerCase()]: filterNameValue,
			}
		})
		?.reduce((result: any, currentObject: any) => {
			return { ...result, ...currentObject }
		}, {})
	return allowedSection
}

export function useChildAllowedSection(providerSection: any) {
	const allowedSection = providerSection?.providerSectionChildren
		?.map((section: any) => {
			const filterNameValue = section?.clientSection?.slug
			return {
				[filterNameValue?.replace(/ /g, '')?.toLowerCase()]: filterNameValue,
			}
		})
		?.reduce((result: any, currentObject: any) => {
			return { ...result, ...currentObject }
		}, {})
	return {
		...allowedSection,
		[providerSection?.clientSection?.slug.replace(/ /g, '')?.toLowerCase()]:
			providerSection?.clientSection?.slug,
	}
}

export function useChildSectionChildren(childSection: any) {
	const allSectionDetails = childSection?.providerSectionChildren
		?.map((section: any) => {
			const filterNameValue = section?.clientSection?.slug
			return {
				[filterNameValue?.replace(/ /g, '')?.toLowerCase()]: section,
			}
		})
		?.reduce((result: any, currentObject: any) => {
			return { ...result, ...currentObject }
		}, {})
	const allowedSectionDetail = useChildAllowedSection(childSection)

	const screenDetailObj = {
		allowedSectionDetail,
		allSectionDetails: {
			...allSectionDetails,
			[childSection?.clientSection?.slug.replace(/ /g, '')?.toLowerCase()]:
				childSection?.clientSection,
		},
	}

	return screenDetailObj
}

//  new function used in task management
export function useChildSectionChild(childSection: any) {
	const allScreenSection = childSection?.providerSectionChildren
		?.map((section: any) => {
			const filterNameValue = section?.clientSection?.slug
			return {
				[filterNameValue?.replace(/ /g, '')?.toLowerCase()]: section,
			}
		})
		?.reduce((result: any, currentObject: any) => {
			return { ...result, ...currentObject }
		}, {})
	const allowedSectionDetail = useChildAllowedSection(childSection)

	const screenDetailObj = {
		allowedSectionDetail,
		allScreenSection,
	}

	return screenDetailObj
}

//  function not in used
export function useSectionPermission(screenName: string) {
	const loginData: any = getLocalStorageDetails(LOCAL_STORAGE?.INHERITED_LOGIN)
	const loginParseData: any = onParseJSON(loginData)
	const allScreensDetails = useGetAllScreens()
	const isScreenAvailable = allScreensDetails?.find((screenObj: any) => {
		// earlier used
		// return screenObj?.clientScreen?.screen?.screenName === screenName
		return screenObj?.clientScreen?.screen?.slug === screenName
	})

	const screenSectionPermission = useSectionScreenWisePermission({
		screenId: isScreenAvailable?.id,
		clientId: loginParseData?.clientId,
	})
	const providerScreenSection = screenSectionPermission?.data?.data

	const isFilterSectionAvailable = providerScreenSection?.providerScreenSections
		?.map((section: any) => {
			const filterNameValue = section?.providerSection?.clientSection?.slug
			return {
				[filterNameValue?.replace(/ /g, '')?.toLowerCase()]: filterNameValue,
			}
		})
		?.reduce((result: any, currentObject: any) => {
			return { ...result, ...currentObject }
		}, {})
	return { ...isFilterSectionAvailable, isScreenFetching: screenSectionPermission?.isFetching }
}

// For Child Tabs
export function useGetChildTabs(screenName: string) {
	const allScreensDetails = useGetAllScreens()
	const isScreenAvailable = allScreensDetails?.find(
		(screenObj: any) => screenObj?.clientScreen?.screen?.slug === screenName,
	)
	return isScreenAvailable?.providerScreenChildren
}

// // For Child Tabs
// export function useGetChildTabs(screenName: string) {
// 	const allScreensDetails = useGetAllScreens()
// 	const isScreenAvailable = allScreensDetails?.find(
// 		(screenObj: any) => screenObj?.clientScreen?.screen?.screenName === screenName,
// 	)
// 	return isScreenAvailable?.providerScreenChildren
// }

export function useSortHandler(param: string | undefined) {
	const [params, setParams] = useUrlQuery()

	return useCallback(
		(column: string) => {
			const [currentSortParamName, sortType] = buildSortParams(param)
			if (!currentSortParamName || currentSortParamName !== column) {
				//if there aren't any sort param or sort param from another field
				return setParams((prev) => ({ ...prev, sort: column }))
			}
			if (currentSortParamName === column && sortType === 'ASC') {
				//if there asc sort param add to sort param "-"
				return setParams((prev) => ({ ...prev, sort: '-' + column }))
			}

			if (currentSortParamName === column && sortType === 'DESC') {
				//if there DESC sort param - remove this sort param
				return setParams((prev) => ({ ...prev, sort: '' }))
			}
		},
		[param, setParams],
	)
}

export function useDebounceParam<T extends object>(
	fieldName: keyof T,
	selector: (data: any) => string = (e) => (e?.target?.value ? e?.target?.value : ''),
	delay = 1000,
) {
	const [, setParams] = useUrlQuery<T>()

	return useDebouncedHandler((e: any) => {
		const value = selector(e)?.trim() // Get the trimmed value

		setParams((prev: any) => {
			if (prev?.page) {
				return {
					...prev,
					page: 1,
					[fieldName]: value,
				}
			} else {
				return {
					...prev,
					[fieldName]: value,
				}
			}
		})
	}, delay)
}

// useItems
export function useItemsDynamicOptions({ clientId }: { clientId: string }) {
	const [search, setSearch] = useState('')
	const params = useParams<{ id: string }>()
	const programId = params?.id
	const { data, isLoading } = useItems({ clientId, programId, search: search, suspense: false })
	return {
		search,
		makeSearch: useCallback((data: string) => {
			setSearch(data)
		}, []),
		isLoading,
		options:
			useMemo(
				() => data?.data.items.map((i: any) => ({ ...i, label: i?.vital, value: i?.id })),
				[data?.data.items],
			) || [],
	}
}
// useTrackingDevices
export function useTrackingDevicesDynamicOptions({ clientId }: { clientId: string }) {
	const [search, setSearch] = useState('')
	const { data, isLoading, isFetching } = useTrackingDevices({
		clientId,
		search: search,
		suspense: false,
	})

	return {
		search,
		makeSearch: useCallback((data: string) => {
			setSearch(data)
		}, []),
		isLoading,
		data,
		isFetching,
		options:
			useMemo(
				() => data?.data.items.map((i: any) => ({ label: i.deviceName, value: i.id })),
				[data?.data.items],
			) || [],
		dataType: data?.data,
	}
}

export function usePatientsDynamicOptions({
	page,
	setPage,
}: {
	page: number
	setPage: (key: number) => void
}) {
	const [search, setSearch] = useState('')
	const [patientOptions, setPatientOptions] = useState<any>([])
	const [patientSearchOptions, setPatientSearchOptions] = useState<any>([])
	const { data, isLoading, isFetching, status, refetch } = usePatients({
		search,
		suspense: false,
		page: page,
	})

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleInputChange = debounceHandler(handleSearch)

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingValues = patientSearchOptions?.map((option: any) => option.value)
			const searchedData = data?.data?.items
				?.filter((item) => !existingValues.includes(item.id))
				?.map((i) => {
					return {
						...i,
						label: i?.id,
						value: i.humanName?.text,
					}
				})
			setPatientOptions([])
			setPatientSearchOptions([...patientSearchOptions, ...searchedData])
		}
	}, [data?.data?.items])

	useEffect(() => {
		let labelValue: any = []
		if (data?.data?.items && !search) {
			const existingValues = patientOptions?.map((option: any) => option.value)
			labelValue = data?.data?.items
				?.filter((item) => !existingValues.includes(item.id))
				?.map((i) => {
					return {
						...i,
						label: i?.id,
						value: i.humanName?.text,
					}
				})
			setPatientSearchOptions([])
			setPatientOptions([...patientOptions, ...labelValue])
		}
	}, [data?.data?.items])

	return {
		data,
		search,
		status,
		isFetching,
		makeSearch: handleInputChange,
		isLoading,
		refetch,
		options: search ? patientSearchOptions : patientOptions,
	}
}

export function useNestedSortHandler(param: string | undefined) {
	const [params, setParams] = useUrlQuery()

	return useCallback(
		(column: string) => {
			const [currentSortParamName, sortType] = buildSortParams(param)
			if (!currentSortParamName || currentSortParamName !== column) {
				//if there aren't any sort param or sort param from another field
				return setParams((prev) => ({ ...prev, innerSort: column }))
			}
			if (currentSortParamName === column && sortType === 'ASC') {
				//if there asc sort param add to sort param "-"
				return setParams((prev) => ({ ...prev, innerSort: '-' + column }))
			}

			if (currentSortParamName === column && sortType === 'DESC') {
				//if there DESC sort param - remove this sort param
				return setParams((prev) => ({ ...prev, innerSort: '' }))
			}
		},
		[param, setParams],
	)
}

export function useProvidersDynamicOptions() {
	const [search, setSearch] = useState('')
	const { data, isLoading, status } = useProviders({
		client: search,
		suspense: false,
	})
	return {
		data,
		search,
		status,
		makeSearch: useCallback((data: string) => {
			setSearch(data)
		}, []),
		isLoading,
		options:
			useMemo(
				() => data?.data?.items.map((i: any) => new SelectOption(i.id, i.humanName?.text)),
				[data?.data.items],
			) || [],
	}
}
export function useQuery() {
	return new URLSearchParams(useLocation().search)
}
export const verifyImageType = (type: string) => {
	switch (type) {
		case 'image/png':
			return true
		case 'image/jpg':
			return true
		case 'image/jpeg':
			return true
		default:
			return false
	}
}

export const handlePhone = (e: any, setFieldValue: any, name = 'phone') => {
	const input = e?.target?.value?.replace(/\D/g, '').substring(0, 10) // First ten digits of input only
	const zip = input.substring(0, 3)
	const middle = input.substring(3, 6)
	const last = input.substring(6, 10)
	if (input.length > 6) {
		setFieldValue(name, `(${zip}) ${middle} - ${last}`)
	} else if (input.length > 3) {
		setFieldValue(name, `(${zip}) ${middle}`)
	} else if (input.length > 0) {
		setFieldValue(name, `(${zip}`)
	}
	return setFieldValue(name, input)
}

export const handleZip = (evt: any) => {
	let value = evt.target.value
	// value = value.replace(/\D/g, '')

	if (
		evt.target.value?.length > 5 &&
		evt.target.value.charAt(evt.target.value.length - 1).includes('-')
	) {
		value = value.replace(/^(\d{5})(\d)/, '$1$2')
		return (evt.target.value = value)
	} else {
		value = value.replace(/^(\d{5})(\d)/, '$1-$2')
		// evt = evt ? evt : window.event
		return (evt.target.value = value)
	}
}

// New function for ZIP CODE format
export const formatZipCode = (input: string) => {
	let value = input.replace(/\D/g, '') // Remove non-digit characters
	if (value.length > 5) {
		// Format ZIP code if length exceeds 5
		value = value.substring(0, 5) + '-' + value.substring(5, 9)
	}
	return value
}

// New function for restrict Numaric
export const handleRestrictNumeric = (evt: any) => {
	if (/[^A-Za-z\s\-']/.test(evt.key)) {
		evt.preventDefault()
	}
}

export const handleRestrictCharacters = (evt: any) => {
	;['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
}

export const blockInvalidChar = (e: any) =>
	['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

export const IS_PHONE_OPTIONS = [{ value: '+1', label: '+1' }]
// export const useCommonGlobalCodeSelect = (e: string) => {
// 	const globalCodeCategoryData = useGlobalCodeCategoryByCategoryCode({
// 		id: e,
// 		clientId: getClientDetails()?.clientId,
// 		suspense: true,
// 	})
// 	const globalCodeData = globalCodeCategoryData?.data?.data?.globalCodes?.map(
// 		(item: IGlobalCodeSelect | any) => {
// 			return {
// 				label: item?.globalCodesName,
// 				value: item?.slug,
// 				id: item?.id,
// 			}
// 		},
// 	)
// 	return globalCodeData
// }

// Get all global codes as per client id

export const useCommonGlobalCodeSelect = (e: string) => {
	const globalCodes = useGlobalCode()
	const globalCodeCategoryData = globalCodes?.find((item) => item?.categoryCode === e)
	const globalCodeData = globalCodeCategoryData?.globalCodes?.map((item) => {
		return {
			label: item?.globalCodesName,
			value: item?.slug,
			id: item?.id,
		}
	})
	return globalCodeData ?? []
}
export const useCommonGlobalCodeOptions = ({
	globalCodeName = '',
	isEnabled = true,
	suspense = true,
}: Partial<{
	globalCodeName: string
	isEnabled: boolean
	suspense: boolean
}>) => {
	const {
		data: globalCodeCategoryData,
		isLoading,
		isFetching,
	} = useGlobalCodeCategoryByCategoryCode({
		id: globalCodeName,
		clientId: getClientDetails()?.clientId,
		isEnabled,
		suspense: suspense,
	})

	const [options, setOptions] = useState<ISelectIdOption[] | []>([])
	useEffect(() => {
		if (globalCodeCategoryData?.data?.globalCodes) {
			const optionsDetail = globalCodeCategoryData?.data?.globalCodes?.map(
				(item: IGlobalCodeSelect | any) => {
					return {
						label: item?.globalCodesName,
						value: item?.slug,
						id: item?.id,
					}
				},
			)

			setOptions(optionsDetail)
		}
	}, [globalCodeCategoryData?.data?.globalCodes])

	return {
		options,
		isFetching,
		isLoading,
	}
}

export function useClientsSummaryReportDynamicOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	setPage,
}: {
	limit?: number
	page?: number
	setPage: (key: number) => void
}) {
	const [clientOptions, setClientOptions] = useState<any>([])
	const [clientSearchOptions, setClientSearchOptions] = useState<any>([])
	const [clientSearch, setClientSearch] = useState('')
	const { data, isLoading, isFetching, refetch } = useAllClients({
		search: clientSearch,
		limit,
		page,
	})

	const handleSearch = (data: string | undefined) => {
		setClientSearch(data || '')
		setPage(1)
	}

	const handleInputChange = debounceHandler(handleSearch)

	useEffect(() => {
		if (data?.data?.items && clientSearch) {
			const existingValues = clientSearchOptions?.map((option: any) => option.value)
			const searchedData = data?.data?.items
				?.filter((item) => !existingValues.includes(item.id))
				?.map((i) => {
					return {
						...i,
						label: i?.title,
						value: i?.id,
					}
				})
			setClientOptions([])
			setClientSearchOptions([...clientSearchOptions, ...searchedData])
		}
	}, [data?.data?.items])

	useEffect(() => {
		let labelValue: any = []
		if (data?.data?.items && !clientSearch) {
			const existingValues = clientOptions?.map((option: any) => option.value)
			labelValue = data?.data?.items
				?.filter((item) => !existingValues.includes(item.id))
				?.map((i) => {
					return {
						...i,
						label: i?.title,
						value: i?.id,
					}
				})
			setClientSearchOptions([])
			setClientOptions([...clientOptions, ...labelValue])
		}
	}, [data?.data?.items])

	return {
		search: clientSearch,
		makeSearch: handleInputChange,
		isLoading,
		options: clientSearch ? clientSearchOptions : clientOptions,
		isFetching,
		meta: data?.data,
		refetch: refetch,
	}
}

// export const setLocalStorageDetails = (keyName: string, value: string): any | null => {
export const setLocalStorageDetails = (
	keyName: string,
	value: string,
	callback?: () => void,
): void => {
	localStorage?.setItem(keyName, value)
	// Call the callback if provided
	if (callback) {
		callback()
	}
}

export const providerRoleBoolean = (profile: any) => {
	if (profile?.providerRole?.length > 1) {
		return true
	} else {
		return false
	}
}

// type Profile = {
// 	role?: string
// 	providerRole?: string[]
// }

// export const superAdmin = (profile: Profile): boolean => {
// 	const adminRole = 'admin'
// 	const superAdminRole = 'superAdmin'
// 	return (
// 		profile?.role === adminRole ||
// 		profile?.role === superAdminRole ||
// 		(profile?.providerRole?.length ?? 0) > 1
// 	)
// }

export const useRolePermission = (providerService?: string | undefined) => {
	/**
	 * Context Section
	 */
	const [params] = useUrlQuery<any>()
	const role = useParams<{ id: string }>()
	const roleInformationData = useContext(RoleContext)
	/**
	 * Variable Section
	 */
	const isClient = params?.client
	const isRole = role?.id
	const providerServiceId = params?.isProviderServiceId ?? providerService

	/**
	 * State Section
	 */
	const [permissionData, setPermissionData] = useState<any>([])
	/**
	 * Destructure  Section
	 */
	const {
		setAllPermissions,
		dataValue: {
			data: {
				data: { id },
			},
		},
	} = roleInformationData
	/**
	 * Custom hooks  Section
	 */
	const moduleData: any = useGetModuleForRole({
		providerService: providerServiceId,
		clientId: isClient,
		role: isRole,
		providerRoleId: params?.providerRoleId,
	})

	const isModuleDataFetching = moduleData?.isFetching ?? false
	const { mutateAsync: createModulesAttributes, isLoading: isModuleLoading } =
		useSpecificModulesAttribute({
			clientId: getClientDetails()?.clientId,
		})
	const { mutateAsync: create } = useAssignALLModulesToProvider({
		clientId: getClientDetails()?.clientId,
	})

	/**
	 * State Section
	 */

	function handleAllPermissions(e: any) {
		const { checked } = e.target
		setAllPermissions(checked)
		const { clientServiceModules } = permissionData

		clientServiceModules?.map((clientServiceModulesObj: any) => {
			return (clientServiceModulesObj.clientModule.isAssigned = e.target.checked)
		})

		clientServiceModules?.map((clientServiceModulesObj: any) => {
			clientServiceModulesObj.clientModule.clientModulesAttribute?.map(
				(clientModulesAttributeObj: any) => {
					clientModulesAttributeObj.isAssigned = e.target.checked
				},
			)
		})

		const providerPayload = () => {
			const payload = {
				role: id,
				clientService: permissionData?.id,
				isAssigned: checked,
			}

			return payload
		}

		create(providerPayload())
	}

	async function handeleModuleChange(e: any, index: any) {
		try {
			permissionData[index].isAssigned = !permissionData[index].isAssigned
			// //code for select all attribute

			permissionData[index].providerModulesAttribute.map((providerModulesAttributeObj: any) => {
				providerModulesAttributeObj.isAssigned = permissionData[index].isAssigned
			})

			const responseModules = await createModulesAttributes(
				onCreatePayload(permissionData, index, providerServiceId, params),
			)
			setPermissionData([...permissionData])
			successNotify(responseModules?.data?.message)
		} catch (err: any) {
			errorNotify(err?.response?.data?.message)
		}
	}

	const onCreatePayload = (
		permissionData: any,
		moduleIndex: number,
		providerServicesId: any,
		params?: any,
	) => {
		const modulePayload = getModulePayload(
			permissionData[moduleIndex],
			id,
			providerServicesId,
			params,
		)
		return modulePayload.payload
	}

	async function handeleAttributeChange(moduleIndex: any, attributeIndex: any) {
		permissionData[moduleIndex].providerModulesAttribute[attributeIndex].isAssigned =
			!permissionData[moduleIndex].providerModulesAttribute[attributeIndex].isAssigned

		const atLeastOneChildChecked = permissionData[moduleIndex].providerModulesAttribute.some(
			(providerModulesAttributeuteObj: any) => providerModulesAttributeuteObj?.isAssigned,
		)
		if (atLeastOneChildChecked) {
			permissionData[moduleIndex].isAssigned = atLeastOneChildChecked
		}

		setPermissionData([...permissionData])
		try {
			const responseAttributes = await createModulesAttributes(
				onCreatePayload(permissionData, moduleIndex, providerServiceId),
			)
			successNotify(responseAttributes?.data?.message)
		} catch (err: any) {
			errorNotify(err?.response?.data?.message)
		}
	}
	/**
	 * *useEffects Section
	 * @param useEffectName
	 */
	useEffect(() => {
		if (moduleData?.data?.data?.length) {
			setPermissionData(moduleData?.data?.data)
		} else {
			setPermissionData([])
		}
	}, [moduleData?.data?.data])

	return {
		handleAllPermissions,
		handeleModuleChange,
		handeleAttributeChange,
		permissionData,
		setPermissionData,
		isModuleDataFetching,
		isModuleLoading,
	}
}

//funtion for get payload when service isAssigned=false, nodeType='service'
function getServicePayload(
	clientService: any,
	roleId: any,
	particularClientServiceModulesObj: any,
) {
	// 'particularClientServiceModulesObj', particularClientServiceModulesObj)
	const { clientModules, providerModulesAttribute } = particularClientServiceModulesObj
	const childrenDetail = []
	const childrenObj = {
		id: clientModules.id,
		isAssigned: clientModules.isAssigned,
		children: providerModulesAttribute.map((providerModulesAttributeObj: any) => ({
			id: providerModulesAttributeObj.clientModulesAttribute.id,
			isAssigned: providerModulesAttributeObj.isAssigned,
		})),
	}
	childrenDetail.push(childrenObj)
	const payload = {
		payload: {
			roleId: roleId,
			nodeType: 'Service',
			nodeId: clientService?.id,
			isAssigned: clientService?.isAssigned,
			children: childrenDetail,
		},
	}
	return payload
}

//function for get payload when service isAssigned=true, nodeType=module
function getModulePayload(
	particularClientServiceModulesObj: any,
	roleId: any,
	proividerServiceId: any,
	params?: any,
) {
	const { clientModules, providerModulesAttribute } = particularClientServiceModulesObj

	const payload = {
		payload: {
			parentId: proividerServiceId,
			roleId: roleId,
			nodeType: 'Module',
			nodeId: clientModules.id,
			isAssigned: particularClientServiceModulesObj?.isAssigned,
			children: providerModulesAttribute.map((providerModulesAttributeObj: any) => ({
				id: providerModulesAttributeObj.clientModulesAttribute.id,
				isAssigned: providerModulesAttributeObj.isAssigned,
			})),
			providerRoleId: params?.providerRoleId,
		},
	}
	if (!params?.providerRoleId) {
		delete payload?.payload?.providerRoleId
	}
	return payload
}

export const useMessageStoreData = () => {
	const [messageData, setMessageData] = useState<any>()
	const [groupId, setGroupId] = useState('')
	const [conversationId, setConversationId] = useState('')
	const [memberId, setMemberId] = useState('')
	const [isSocketConnected, setIsSocketConnected] = useState(false)
	return {
		conversationId,
		setConversationId,
		memberId,
		setMemberId,
		groupId,
		setGroupId,
		isSocketConnected,
		setIsSocketConnected,
		messageData,
		setMessageData,
	}
}

export const useTaskStoreData = () => {
	const [activeStepTitle, setActiveStepTitle] = useState<string>(STEPPER_TITLE.TASK_MODE)
	const [categoryId, setCategoryId] = useState('')
	const [activeTaskMode, setActiveTaskMode] = useState('')
	const [formSubmit, setFormSubmit] = useState(false)
	const [selectedTaskId, setSelectedTaskId] = useState('')
	const [taskType, setTaskType] = useState({} as any)
	const [task, setTask] = useState({} as any)
	const [status, setStatus] = useState({} as any)
	const [noteId, setNoteId] = useState('')
	const [taskTypeValidate, setTaskTypeValidate] = useState(false)
	return {
		selectedTaskId,
		setSelectedTaskId,
		activeStepTitle,
		setActiveStepTitle,
		formSubmit,
		setFormSubmit,
		activeTaskMode,
		setActiveTaskMode,
		categoryId,
		setCategoryId,
		taskType,
		setTaskType,
		task,
		setTask,
		status,
		setStatus,
		noteId,
		setNoteId,
		taskTypeValidate,
		setTaskTypeValidate,
	}
}

export const useTaskCptData = () => {
	const [selectedCptData, setSelectedCptData] = useState([])
	const [selectedCptIds, setSelectedCptIds] = useState([])

	return {
		selectedCptData,
		setSelectedCptData,
		selectedCptIds,
		setSelectedCptIds,
	}
}

export const useTimeStoreData = () => {
	const [time, setTime] = useState<any>(0)
	const [isActive, setIsActive] = useState(false)
	const [isPaused, setIsPaused] = useState(true)
	const [patientName, setPatientName] = useState('')
	const [showTaskModal, setShowTaskModal] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [showCallModal, setShowCallModal] = useState(false)
	const [isTrackerStart, setIsTrackerStart] = useState(false)
	const [isCompleted, setIsCompleted] = useState(false)
	const [startTrackerTime, setStartTrackerTime] = useState<any>()
	const [activeUrl, setActiveUrl] = useState('')
	const [deleteProviderRole, setDeleteProviderRole] = useState<any>()
	const [calenderCallData, setCalendarCallData] = useState<any[]>([])
	const [calendarCallTime, setCalendarCallTime] = useState<any>(0)
	const [isCallPaused, setIsCallPaused] = useState(true)
	const [startCall, setStartCall] = useState(false)
	const [startTime, setStartTime] = useState<any>(null)
	const [endTime, setEndTime] = useState<any>(null)
	const [slotId, setSlotId] = useState('')
	const [openCallTrackingForm, setOpenCallTrackingForm] = useState(false)
	return {
		time,
		setTime,
		isActive,
		setIsActive,
		isPaused,
		setIsPaused,
		isCompleted,
		setIsCompleted,
		showModal,
		setShowModal,
		isTrackerStart,
		setIsTrackerStart,
		showTaskModal,
		setShowTaskModal,
		startTrackerTime,
		setStartTrackerTime,
		patientName,
		activeUrl,
		setActiveUrl,
		setPatientName,
		deleteProviderRole,
		setDeleteProviderRole,
		calenderCallData,
		setCalendarCallData,
		calendarCallTime,
		setCalendarCallTime,
		startCall,
		setStartCall,
		startTime,
		setStartTime,
		endTime,
		setEndTime,
		slotId,
		setSlotId,
		openCallTrackingForm,
		setOpenCallTrackingForm,
		showCallModal,
		setShowCallModal,
		isCallPaused,
		setIsCallPaused,
	}
}

export function useServices() {
	const [search, setSearch] = useState('')
	const { data, isFetching } = useGlobalServices({ search: search, suspense: false })

	return {
		search,
		makeSearch: useCallback((data: string) => {
			setSearch(data)
		}, []),
		isFetching,
		options:
			useMemo(() => {
				return data?.data?.items?.map((i: any) => {
					return { label: i?.serviceName?.toUpperCase(), value: i?.id }
				})
			}, [data?.data?.items]) || [],
	}
}

export const useAssignedModulesPermission = (props?: any) => {
	/**  Context Section */
	const [params] = useUrlQuery<any>()
	const isAssignClient = params?.isAssign
	const isClientServiceId = params?.isClientServiceId

	/**  Variables Section */

	const { allPermissions, setAllPermissions, setAssignedModulesScreenChange } = props
	/**  Custom hook  Section */
	const isServiceSpecificModules = useServiceSpecificModules({ serviceId: isClientServiceId })
	const { mutateAsync: create, isLoading } = useCreateAssignModule()
	const clientServiceId = params?.isClientServiceId

	const isServiceSpecificModulesResponseData = isServiceSpecificModules?.data?.data ?? []
	const isServiceSpecificModulesFetching = isServiceSpecificModules?.isFetching
	const isServiceSpecificModulesLoading = isServiceSpecificModules?.isLoading
	/**  Function Section */

	const payloadForAssigningModules = (
		clientModules?: IPayloadForAssignedModules,
		clientServiceId?: string,
	) => {
		const clientModulesResponse = {
			clientModuleName: clientModules?.moduleName,
			isAssigned: clientModules?.isAssigned || clientModules?.isRequired,
			modules: clientModules?.id,
			order: clientModules?.order,
			slug: clientModules?.slug,
			isActive: clientModules?.isActive,
			id: clientModules?.clientModules?.id,
			clientModuleImage: clientModules?.moduleImage,
		}
		if (!clientModules?.clientModules) {
			delete clientModulesResponse?.id
		}
		const payload = {
			clientService: clientServiceId,
			clientModule: clientModulesResponse,
			client: isAssignClient,
		}
		return payload
	}

	async function handeleModuleChange(e: any, index: any) {
		allPermissions[index].isAssigned = !allPermissions[index].isAssigned
		setAllPermissions([...allPermissions])

		try {
			const responseModules = await create(
				payloadForAssigningModules(allPermissions[index], clientServiceId),
			)

			successNotify(responseModules?.data?.message)
			// await isQueryModules.invalidateQueries([ENDPOINTS.CLIENT_MODULES_ACCORDING_TO_SERVICE])
		} catch (err: any) {
			errorNotify(err?.response?.data?.message)
			// await isQueryModules.invalidateQueries([ENDPOINTS.CLIENT_MODULES_ACCORDING_TO_SERVICE])
		}
	}
	/**
	 * *useEffects Section
	 * @param useEffectName
	 */
	useEffect(() => {
		if (isServiceSpecificModulesResponseData) {
			setAllPermissions(isServiceSpecificModulesResponseData)
		}
	}, [isServiceSpecificModules?.data?.data])
	/**  Return section */
	return {
		handeleModuleChange,
		allPermissions,
		setAllPermissions,
		isServiceSpecificModulesFetching,
		isServiceSpecificModulesLoading,
		isLoading,
	}
}
export const globalRouter = { navigate: null } as {
	navigate: null | NavigateFunction
}
//  To get logged in user client detailsdetails
export const getLoggedInUserClientDetail = () => {
	const loggedInUserClientDetail = getLocalStorageDetails(LOCAL_STORAGE?.INHERITED_LOGIN)
	const parseloggedInUserClientDetail = onParseJSON(loggedInUserClientDetail)
	return parseloggedInUserClientDetail
}
export const getClientDetails = () => {
	const userClientDetail = getLoggedInUserClientDetail()
	return {
		clientId: userClientDetail?.clientDetails?.clientId,
		clientName: userClientDetail?.clientDetails?.clientLabel,
		carehalo: userClientDetail?.carehalo ?? false,
	}
}
//  To get role of logged in user
export const getRoleName = () => {
	const userClientDetail = getLoggedInUserClientDetail()
	const roleName = userClientDetail?.roleDetails?.rolesSlug
	return roleName
}

export const getLoggedInUserName = () => {
	const userClientDetail = useMyProfile()

	const userName = userClientDetail?.humanName?.text
	const userId = userClientDetail?.id
	return { userName, userId }
}

export const setCommonFieldValue = ({
	profileLabelValue,
	name,
	setFieldValue,
}: {
	profileLabelValue?: any
	name: string
	setFieldValue: any
}) => {
	if (profileLabelValue) {
		return setFieldValue(name, profileLabelValue)
	} else {
		return setFieldValue(name, null)
	}
}

export function useStatesList({ clientId, state }: any) {
	return useReactQuery(
		[ENDPOINTS.STATES_LIST, clientId, state],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getAllStates(clientId, state)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
		},
	)
}

/**
 * *Get All States
 */
export function getAllStates(
	clientId: string | undefined,
	params: Partial<IProgramParams> | any,
	source?: CancelTokenSource,
) {
	const promise = serviceAxios.get(onCreateEndpointsWithClientid(ENDPOINTS.STATES_LIST), {
		params: {
			search: params,
		},
	})
	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

export const useFilterOutLayoutKeyValue = ({
	sectionName,
	screenDetails,
	layoutInformationData,
}: {
	sectionName: string
	screenDetails: any
	layoutInformationData: any
}) => {
	const getCustomSectionDetails = onGetCustomSectionDetail(screenDetails, sectionName)
	const fieldLabelValue = getCustomSectionDetails?.providerColumns?.map((screenTempObj: any) => {
		const { fieldLabel, fieldName } = onGetFilterColumnDetail(screenTempObj)
		return {
			fieldLabel: fieldLabel,
			fieldValue: layoutInformationData[fieldName],
		}
	})

	return fieldLabelValue ?? []
}

export function capitalizeFirstLetter(str: string) {
	return str.charAt(0).toUpperCase() + str.slice(1)
}

export function useTaskTypesDropdownOptions({
	id = '',
	patient,
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	sort,
	setPage,
}: {
	patient?: string
	limit?: number
	page?: number
	sort?: string
	setPage: (key: number) => void
	id: string | null
}) {
	const [search, setSearch] = useState('')
	const [options, setOptions] = useState<any>([])
	const [searchOptions, setSearchOptions] = useState<any>([])
	const { data, refetch, isFetching } = useMemberProviderTask({
		patient,
		taskType: id,
		limit,
		page,
		sort,
		hitQuery: id,
	})
	useEffect(() => {
		let labelValue: any = []
		if (data?.data?.items && !search) {
			const existingValues = options?.map((option: any) => option.value)
			labelValue = data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((i: any) => {
					return {
						...i,
						label: i?.name,
						value: i?.id,
					}
				})
			setSearchOptions([])
			setOptions([...options, ...labelValue])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingValues = searchOptions?.map((option: any) => option.value)
			const items = data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((i: any) => {
					return {
						...i,
						label: i?.name,
						value: i?.id,
					}
				})
			setOptions([])
			setSearchOptions([...searchOptions, ...items])
		}
	}, [data?.data?.items])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleSearchInputChange = debounceHandler(handleSearch)

	return {
		search,
		makeSearch: handleSearchInputChange,
		isFetching,
		options: search ? searchOptions : options,
		refetch: refetch,
		meta: data?.data,
	}
}
export function useSmbpProgramDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	setPage,
	isEnabled = true,
}: {
	limit?: number
	page?: number
	setPage: (key: number) => void
	isEnabled?: boolean
	suspense?: boolean
}) {
	const [options, setOptions] = useState<ISelectIdOption[] | []>([])
	const [searchOptions, setSearchOptions] = useState<ISelectIdOption[] | []>([])
	const [search, setSearch] = useState('')
	const { data, isFetching, refetch, isFetched } = useSmbpPrograms({
		limit,
		page,
		search,
		isEnabled,
	})
	useEffect(() => {
		if (data?.data?.items) {
			const optionValues = search ? searchOptions : options
			const existingValues = optionValues?.map((option) => option.value)
			const newOptions = data?.data?.items
				.filter((item) => !existingValues.includes(item.id || ''))
				.map((i) => ({
					...i,
					label: i?.programCriteria?.programTitle || '',
					value: i?.id || '',
				}))
			if (search) {
				setSearchOptions([...searchOptions, ...newOptions])
			} else {
				setOptions((prevOptions) => [...prevOptions, ...newOptions])
			}
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (search) {
			setSearchOptions([])
			setOptions([])
		}
	}, [search])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleSearchOptions = debounceHandler(handleSearch)

	return {
		search,
		makeSearch: handleSearchOptions,
		isFetching,
		options: search ? searchOptions : options,
		meta: data?.data,
		refetch: refetch,
		isFetched,
	}
}
export const AllOWED_FILES: any = {
	IMAGE: ['png', 'jpeg', 'jpg', 'gif'],
	DOCUMENTS: ['docx', 'doc', 'xlsx', 'xls', 'pdf', 'avi'],
	VIDEO: ['mp4', 'x-msvideo'],
}

export const ALLOWED_ICON_FILES = {
	DOCUMENTS: ['docx', 'doc'],
	EXCELS: ['xlsx', 'xls'],
	PDFS: ['pdf'],
	VIDEOS: ['mp4', 'avi'],
	IMAGE: ['png', 'jpeg', 'jpg', 'gif'],
}

export const capitalizeTwoCharacters = (str: string) => {
	const cleanedStr = str?.replace(/\s+/g, '')

	if (cleanedStr?.length === 2) {
		return cleanedStr?.toUpperCase()
	}
	return cleanedStr
}

export function useTemplateCodeDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	sort,
}: {
	limit?: number
	page?: number
	sort?: string
}) {
	const [search, setSearch] = useState('')
	const [options, setOptions] = useState<any>([])
	const [searchOptions, setSearchOptions] = useState<any>([])
	const { data, isFetching, refetch } = useTemplates({ limit, page, search, sort })
	useEffect(() => {
		let labelValue: any = []
		if (data?.data?.items && !search) {
			const existingValues = options?.map((option: any) => option.value)
			labelValue = data?.data?.items
				?.filter((item) => !existingValues.includes(item.id))
				?.map((i) => {
					return {
						label: i?.templateCode,
						value: i?.id,
						templateName: i?.title,
					}
				})
			setSearchOptions([])
			setOptions([...labelValue, ...options])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingValues = searchOptions?.map((option: any) => option.value)
			const items = data?.data?.items
				?.filter((item) => !existingValues.includes(item.id))
				?.map((i) => {
					return {
						...i,
						label: i?.templateCode,
						value: i?.id,
						templateName: i?.title,
					}
				})
			setOptions([])
			setSearchOptions([...searchOptions, ...items])
		}
	}, [data?.data?.items])

	return {
		search,
		makeSearch: useDebouncedHandler((data: string | undefined) => {
			setSearch(data || '')
		}, 2000),
		isFetching,
		options: search ? searchOptions : options,
		refetch: refetch,
		meta: data?.data,
	}
}

// A common function to create a search handler
export const createSearchHandler = (
	setState: React.Dispatch<React.SetStateAction<string>>,
	setPage: (value: number) => void,
) => {
	return (data: string | undefined) => {
		setState(data || '') // Update the state with the provided data
		setPage(1)
	}
}

export function toCamelCase(str: any) {
	if (str.startsWith('/')) {
		str = str.slice(1)
	}

	// Check if the string is all uppercase
	if (str.toUpperCase() === str) {
		// Convert the string to lowercase and return it
		str = str.toLowerCase()
	}

	return str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter: string, index: number) {
			return index === 0 ? letter.toLowerCase() : letter.toUpperCase()
		})
		.replace(/\s+/g, '')
}
export function escapeRegExp(str: string) {
	return str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function useHealthCareDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	setPage,
	isEnabled,
	sort,
}: {
	limit?: number
	page?: number
	isEnabled?: boolean
	sort?: string
	setPage: (key: number) => void
}) {
	const [options, setOptions] = useState<any>([])
	const [searchOptions, setSearchOptions] = useState<any>([])
	const [search, setSearch] = useState('')
	const { data, isFetching, refetch } = useHealthCarePartners({
		limit,
		page,
		sort,
		search,
		isEnabled,
	})
	useEffect(() => {
		if (data?.data?.items && !search) {
			const existingValues = options?.map((option: any) => option.value)
			const newOptions = data?.data?.items
				.filter((item: any) => !existingValues.includes(item.id))
				.map((i: any) => ({
					...i,
					label: i?.companyName,
					value: i?.id,
				}))
			setSearchOptions([])
			setOptions([...options, ...newOptions])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const newOptions = data?.data?.items.map((i: any) => ({
				...i,
				label: i?.companyName,
				value: i?.id,
			}))
			setOptions([])
			setSearchOptions([...newOptions])
		}
	}, [data?.data?.items])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}
	const handleSearchOptions = debounceHandler(handleSearch)
	return {
		search,
		makeSearch: handleSearchOptions,
		isFetching,
		options: search ? searchOptions : options,
		meta: data?.data,
		refetch: refetch,
	}
}

export function useHealthCarePartnerDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	setPage,
	program,
	sort,
}: {
	limit?: number
	page?: number
	setPage: (key: number) => void
	sort?: string
	program: string
}) {
	const [options, setOptions] = useState<any>([])
	const [searchOptions, setSearchOptions] = useState<any>([])
	const [search, setSearch] = useState('')
	const { data, isFetching, refetch } = usePartnerList({
		limit,
		page,
		search,
		program,
		sort,
	})
	useEffect(() => {
		if (data?.data?.items && !search) {
			const existingValues = options?.map((option: any) => option.value)
			const newClientOptions = data?.data?.items
				.filter((item: any) => !existingValues.includes(item.id))
				.map((i: any) => ({
					label: i?.companyName,
					value: i?.id,
				}))
			setSearchOptions([])
			setOptions([...options, ...newClientOptions])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const newClientOptions = data?.data?.items.map((i: any) => ({
				label: i?.companyName,
				value: i?.id,
			}))
			setOptions([])
			setSearchOptions([...newClientOptions])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (program) {
			setSearchOptions([])
			setOptions([])
		}
	}, [program])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}
	const handleSearchOptions = debounceHandler(handleSearch)
	return {
		search,
		makeSearch: handleSearchOptions,
		isFetching,
		options: search ? searchOptions : options,
		meta: data?.data,
		refetch: refetch,
	}
}

export function useDashboardPartnerDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	setPage,
	program,
	sort,
}: {
	limit?: number
	page?: number
	setPage: (key: number) => void
	sort?: string
	program: string
}) {
	const [options, setOptions] = useState<ISelectIdOption[] | []>([])
	const [searchOptions, setSearchOptions] = useState<ISelectIdOption[] | []>([])
	const [search, setSearch] = useState('')
	const { data, isFetching, refetch } = useDashboardPartnerList({
		limit,
		page,
		search,
		program,
		sort,
	})
	useEffect(() => {
		if (data?.data?.items && !search) {
			const existingValues = options?.map((option: any) => option.value)
			const newClientOptions = data?.data?.items
				.filter((item: any) => !existingValues.includes(item.id))
				.map((i: any) => ({
					label: i?.companyName,
					value: i?.id,
				}))
			setSearchOptions([])
			setOptions([...options, ...newClientOptions])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const newClientOptions = data?.data?.items.map((i: any) => ({
				label: i?.companyName,
				value: i?.id,
			}))
			setOptions([])
			setSearchOptions([...newClientOptions])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (program) {
			setOptions([])
			setSearchOptions([])
		}
	}, [program])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}
	const handleSearchOptions = debounceHandler(handleSearch)
	return {
		search,
		makeSearch: handleSearchOptions,
		isFetching,
		options: search ? searchOptions : options,
		meta: data?.data,
		refetch: refetch,
	}
}

export function useGroupProvidersDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	isEnabled = true,
	setPage,
	clientId = '',
	partner = '',
}: {
	limit?: number
	page?: number
	isEnabled?: boolean
	setPage: (key: number) => void
	clientId: string
	partner: string
}) {
	const [search, setSearch] = useState('')
	const [options, setOptions] = useState<any>([])
	const [searchOptions, setSearchOptions] = useState<any>([])

	const { data, isFetching, refetch } = useProviderGroupsList({
		page,
		clientId,
		partner,
		search,
		isEnabled,
		limit,
	})

	useEffect(() => {
		setOptions([])
		setSearchOptions([])
		setSearch('')
	}, [partner])

	useEffect(() => {
		let labelValue: any = []
		if (data?.data?.items && !search) {
			const existingValues = options?.map((option: any) => option.value)
			labelValue = data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((i: any) => {
					return {
						label: i?.humanName?.text,
						value: i?.id,
					}
				})
			setSearchOptions([])
			setOptions([...options, ...labelValue])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingValues = searchOptions?.map((option: any) => option.value)
			const items = data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((i: any) => {
					return {
						label: i?.humanName?.text,
						value: i?.id,
					}
				})
			setOptions([])
			setSearchOptions([...searchOptions, ...items])
		}
	}, [data?.data?.items])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleSearchInputChange = debounceHandler(handleSearch)

	return {
		search,
		makeSearch: handleSearchInputChange,
		isFetching,
		options: search ? searchOptions : options,
		refetch: refetch,
		meta: data?.data,
	}
}

export function useSlotsMembersDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	isEnabled = true,
	setPage,
	clientId = '',
	partner = '',
	provider = '',
}: {
	limit?: number
	page?: number
	isEnabled?: boolean
	setPage: (key: number) => void
	clientId: string
	partner: string
	provider?: string
}) {
	const [search, setSearch] = useState('')
	const [options, setOptions] = useState<any>([])
	const [searchOptions, setSearchOptions] = useState<any>([])

	const { data, isFetching, refetch } = useSlotContainerMembers({
		clientId,
		provider,
		partner,
		page,
		search,
		isEnabled,
		limit,
	})

	useEffect(() => {
		setOptions([])
		setSearchOptions([])
		setSearch('')
	}, [partner, provider])

	useEffect(() => {
		let labelValue: any = []
		if (data?.data?.items && !search) {
			const existingValues = options?.map((option: any) => option.value)
			labelValue = data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((i: any) => {
					const email = i?.patientContactPoints?.[0]?.contactPoint?.text
					const phone = i?.phone ? i?.phoneNumber : ''
					const contactInfo = email ? email : phone
					const name = i?.humanName?.text
					const label = contactInfo ? `${name} (${contactInfo})` : name // Format the label
					return {
						label,
						value: i?.id,
					}
				})
			setSearchOptions([])
			setOptions([...options, ...labelValue])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingValues = searchOptions?.map((option: any) => option.value)
			const items = data?.data?.items
				?.filter((item: any) => !existingValues?.includes(item.id))
				?.map((i: any) => {
					const email = i?.patientContactPoints?.[0]?.contactPoint?.text
					const phone = i?.phone ? i?.phoneNumber : ''
					const contactInfo = email ? email : phone
					const name = i?.humanName?.text
					const label = contactInfo ? `${name} (${contactInfo})` : name // Format the label
					return {
						label,
						value: i?.id,
					}
				})
			setOptions([])
			setSearchOptions([...searchOptions, ...items])
		}
	}, [data?.data?.items])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleSearchInputChange = debounceHandler(handleSearch)

	return {
		search,
		makeSearch: handleSearchInputChange,
		isFetching,
		options: search ? searchOptions : options,
		refetch: refetch,
		meta: data?.data,
	}
}

export function useSlotsProvidersDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	page,
	isEnabled = true,
	setPage,
	partner = '',
}: {
	limit?: number
	page?: number
	isEnabled?: boolean
	setPage: (key: number) => void
	partner: string
}) {
	const [search, setSearch] = useState('')
	const [options, setOptions] = useState<any>([])
	const [searchOptions, setSearchOptions] = useState<any>([])

	const { data, isFetching, refetch } = useProviderList({
		partnerId: partner,
		page,
		search,
		isEnabled,
		limit,
	})

	useEffect(() => {
		setOptions([])
		setSearchOptions([])
		setSearch('')
	}, [partner])

	useEffect(() => {
		let labelValue: any = []
		if (data?.data?.items && !search) {
			const existingValues = options?.map((option: any) => option.value)
			labelValue = data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((i: any) => {
					return {
						label: i?.humanName?.text,
						value: i?.id,
					}
				})
			setSearchOptions([])
			setOptions([...options, ...labelValue])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingValues = searchOptions?.map((option: any) => option.value)
			const items = data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((i: any) => {
					return {
						label: i?.humanName?.text,
						value: i?.id,
					}
				})
			setOptions([])
			setSearchOptions([...searchOptions, ...items])
		}
	}, [data?.data?.items])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleSearchInputChange = debounceHandler(handleSearch)

	return {
		search,
		makeSearch: handleSearchInputChange,
		isFetching,
		options: search ? searchOptions : options,
		refetch: refetch,
		meta: data?.data,
	}
}

//  ENDPOINTS.PROVIDER_UNDER_SPECIALIZATION = /providers/doctor-speciality
export function useProvidersSpecialtyDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	id = '',
	patient = '',
	page,
	setPage,
	partner = '',
}: {
	patient: string
	id: string
	limit?: number
	page?: number
	setPage: (key: number) => void
	partner: string
}) {
	const [search, setSearch] = useState('')
	const [options, setOptions] = useState<any>([])
	const [searchOptions, setSearchOptions] = useState<any>([])
	const { data, isFetching, refetch } = useProvidersDoctorSpecialty({
		id,
		partner,
		patient,
		page,
		search,
		limit,
	})

	useEffect(() => {
		setOptions([])
		setSearchOptions([])
		setSearch('')
	}, [partner])

	useEffect(() => {
		let labelValue: any = []
		if (data?.data?.items && !search) {
			const existingValues = options?.map((option: any) => option.value)
			labelValue = data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((i: any) => {
					return {
						value: i.id,
						label: i?.humanName?.text,
					}
				})
			setSearchOptions([])
			setOptions([...options, ...labelValue])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingValues = searchOptions?.map((option: any) => option.value)
			const items = data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((i: any) => {
					return {
						value: i.id,
						label: i?.humanName?.text,
					}
				})
			setOptions([])
			setSearchOptions([...searchOptions, ...items])
		}
	}, [data?.data?.items])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleSearchInputChange = debounceHandler(handleSearch)

	return {
		search,
		makeSearch: handleSearchInputChange,
		isFetching,
		options: search ? searchOptions : options,
		refetch: refetch,
		meta: data?.data,
	}
}

//  ENDPOINTS.PROVIDER_UNDER_SPECIALIZATION = /providers/doctor-speciality
export function useConditionsAssigneeDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	id = '',
	page,
	setPage,
	isEnabled = true,
}: {
	id: string
	limit?: number
	page?: number
	setPage: (key: number) => void
	isEnabled?: boolean
}) {
	const [search, setSearch] = useState('')
	const [options, setOptions] = useState<any>([])
	const [searchOptions, setSearchOptions] = useState<any>([])
	const { data, isFetching, refetch } = useConditionsAssignee({
		id,
		page,
		search,
		limit,
		isEnabled,
	})

	useEffect(() => {
		setOptions([])
		setSearchOptions([])
		setSearch('')
	}, [id])

	useEffect(() => {
		let labelValue: any = []
		if (data?.data?.items && !search) {
			const existingValues = options?.map((option: any) => option.value)
			labelValue = data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((i: any) => {
					return {
						value: i.id,
						label: i?.humanName?.text,
					}
				})
			setSearchOptions([])
			setOptions([...options, ...labelValue])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingValues = searchOptions?.map((option: any) => option.value)
			const items = data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((i: any) => {
					return {
						value: i.id,
						label: i?.humanName?.text,
					}
				})
			setOptions([])
			setSearchOptions([...searchOptions, ...items])
		}
	}, [data?.data?.items])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleSearchInputChange = debounceHandler(handleSearch)

	return {
		search,
		makeSearch: handleSearchInputChange,
		isFetching,
		options: search ? searchOptions : options,
		refetch: refetch,
		meta: data?.data,
	}
}

//  ENDPOINTS.SESSIONS ='/sessions'

export function useProgramSuggestionsSessionsDropdownOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	programId = '',
	page = 1,
	setPage,
	type = SESSION_TYPE.SINGLE_SESSION,
	typeId,
	sort,
	isEnabled = true,
}: {
	programId: string
	limit?: number
	page?: number
	type: string
	typeId: string
	sort: string
	setPage: (key: number) => void
	isEnabled?: boolean
}) {
	const [search, setSearch] = useState('')
	const [options, setOptions] = useState<any>([])
	const [searchOptions, setSearchOptions] = useState<any>([])
	const { data, isFetching, refetch } = useProgramSuggestionSession({
		search,
		type,
		typeId,
		programId,
		sort,
		page,
		limit,
		isEnabled,
	})

	useEffect(() => {
		setOptions([])
		setSearchOptions([])
		setSearch('')
	}, [typeId, programId, type])

	useEffect(() => {
		let labelValue: any = []
		if (data?.data?.items && !search) {
			const existingValues = options?.map((option: any) => option.value)
			labelValue = data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((i: any) => {
					return {
						...i,
						label: i?.title,
						value: i?.id,
					}
				})
			setSearchOptions([])
			setOptions([...options, ...labelValue])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingValues = searchOptions?.map((option: any) => option.value)
			const items = data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((i: any) => {
					return {
						...i,
						label: i?.title,
						value: i?.id,
					}
				})
			setOptions([])
			setSearchOptions([...searchOptions, ...items])
		}
	}, [data?.data?.items])

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleSearchInputChange = debounceHandler(handleSearch)

	return {
		search,
		makeSearch: handleSearchInputChange,
		isFetching,
		options: search ? searchOptions : options,
		refetch: refetch,
		meta: data?.data,
	}
}
