import _ from 'lodash'
import { CancelTokenSource } from 'axios'
import axios from 'services/axios'
import { ENDPOINTS } from 'const/endpoints'
import { commonMessages } from 'utils/messages'
import { IPagination } from 'types/entities'
import { IFullSession, ISessionParams } from './Session.interfaces'
import { SessionPayload } from './Session/Session.types'
import { onCreateEndpointsWithClientid } from 'utils/common'
import { errorNotify } from 'utils/toster'
import { getCurrentTimeZone } from 'utils/time'
/**
 * * createSession
 */
export function createSession(
	clientId: string | undefined,
	data: Partial<SessionPayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.post<SessionPayload>(ENDPOINTS.SESSION_ENTITY + '/' + clientId, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * * updateProgramSession
 */
export function updateProgramSession(
	id: string | undefined,
	clientId: string | undefined,
	data: Partial<SessionPayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.patch<SessionPayload>(
		ENDPOINTS.SESSION_ENTITY + '/' + clientId + '/' + id,
		data,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * *fileUpload
 */

export function cancelFileUpload(
	clientId: string | undefined,
	params: any,
	session: any,
	source?: CancelTokenSource,
) {
	const promise = axios.delete<any>(ENDPOINTS.CANCEL_FILE_UPLOAD + '/' + clientId, { data: params })
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function fileUpload(
	fileId: string | undefined,
	startChunk: any,
	endChunk: any,
	fileSize: any,
	clientId: string | undefined,
	totalChunk: number | null = 0,
	totalChunksUploaded: number | null = 0,
	data: any,
	source?: CancelTokenSource,
) {
	const promise = axios.post<SessionPayload>(
		`${ENDPOINTS.FILE_UPLOAD}/${clientId}?filename=${fileId}`,
		{
			...data,
			totalChunk,
			totalChunksUploaded,
		},
		{
			headers: {
				'Content-Range': `bytes ${startChunk}-${endChunk}/${fileSize}`,
				'file-size': fileSize,
			},
		},
		// data,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * * getProgramViewSession
 */
export function getProgramViewSession(
	id: string | undefined,
	clientId: string | undefined,
	source?: CancelTokenSource,
) {
	const promise = axios.get<IFullSession>(ENDPOINTS.SESSION_ENTITY + '/' + clientId + '/' + id)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * * getProgramAllSession
 */
export function getProgramAllSession(
	params: Partial<ISessionParams>,
	source?: CancelTokenSource,
	id: string | undefined = '',
	clientId?: string | undefined,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<ISessionParams>>>(
		`${ENDPOINTS.SESSIONS}/${clientId}/${id}`,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * * get SuggestionSession
 */
export function getSuggestionSession(params: Partial<ISessionParams>, source?: CancelTokenSource) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<ISessionParams>>>(
		onCreateEndpointsWithClientid(ENDPOINTS?.SESSIONS_SUGGESTION),
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * Download excel file
 */

export function downloadProgramExcelReport(id: string, source?: CancelTokenSource) {
	const checkPath = `${ENDPOINTS.EXPORT_PROGRAM_EXCEL}/${id}?timezone=${getCurrentTimeZone}`
	const promise = axios
		.get(checkPath, {
			responseType: 'blob',
		})
		.then((response) => {
			// Create a temporary URL object to generate a download link
			const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', `session_report_of_patients.xlsx`)

			// Simulate a click event to trigger the download
			document.body.appendChild(link)
			link.click()

			// Clean up the temporary URL object
			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		})
		.catch(() => {
			errorNotify('Error downloading Excel')
		})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
